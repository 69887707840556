import React, {useEffect, useRef, memo, useCallback} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons'
import { DatePicker} from 'antd';
import { Line} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import { isEqual } from "lodash-es";

const { RangePicker } = DatePicker;



const Graphic: React.FC<any> = memo(
  
  ({ data, onReady }) => {
    const calcAverageValue = (data, type) => {
      const items = data.filter((d) => d.name === type);
      return items.length ? items.reduce((a, b) => a + b.value, 0)  : '-';
    };
    const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'name',
      smooth:true,
      legend: {
        position: 'right-top',
        offsetX: 2,
        layout: [
          {
            type: 'hide-overlap',
          },
        ],
        title: {
          text: 'Precio total',
          spacing: 8,
        },
        itemName: {
          formatter: (text, item) => {
            return item.name+": ";
          },
          style: (item, index) => {
            return {
              fill: item.name == "-" ? 'red' : item.name == "+" ? 'green': 'gray',
            };
          },
        },
        itemValue: {
          formatter: (text, item) => {
            return  new Intl.NumberFormat('en-US', {
                
            }).format(calcAverageValue(data, item.value));
          },
          style: (item, index) => {
            return {
              fill: item.value == 0 ? 'red' : 'gray',
            };
          },
        },
      },
      xAxis: {
          nice: true,
          // tickCount: 8,
          // 文本标签
          label: {
            autoRotate: true,
            offset: 10,
            style: {
              fill: '#aaa',
              fontSize: 12,
            },
            formatter: (name) => name,
          },
          title: {
            text: 'Fecha',
            style: {
              fontSize: 16,
            },
          },
          // 坐标轴线的配置项 null 表示不展示
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 2,
              stroke: '#aaa',
            },
            length: 5,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [4, 2],
              },
            },
            alternateColor: 'rgba(0,0,0,0.05)',
          },
        },

        slider: {
          start: 0.0,
          end: 1.0,
        },
        tooltip: {
          title: "Precio del tiempo al aire",
          formatter: (datum) => {
              return { name: datum.name, value: new Intl.NumberFormat('en-US', {
                
            }).format(datum.value),  };
            },
          fields: ['name', 'value'],
        },

        label: {
          layout: [
            {
              type: 'hide-overlap',
            },
          ],
          // 隐藏重叠label
          style: {
            textAlign: 'left',
          },
          formatter: (item) => item.value>=500000? new Intl.NumberFormat('en-US', {
            
        }).format(item.value):"",
        },
        meta:{
          value:{
            range:[0,0.96]
          }
          
        },
        yAxis: {
          label: {
            autoRotate: true,
            style: {
              fill: '#aaa',
              fontSize: 12,
            },
            formatter: (v) => v ? new Intl.NumberFormat('en-US', {
                
            }).format(v) : 0,
          },
          title: {
            text: 'Precio de tiempo al aire',
            style: {
              fontSize: 16,
            },
          },
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 2,
              stroke: '#aaa',
            },
            length: 5,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [4, 2],
              },
            },
            alternateColor: 'rgba(0,0,0,0.05)',
          },
        },
      color: [
          '#5B8FF9',
          '#5AD8A6',
          '#5D7092',
          '#F6BD16',
          '#E8684A',
          '#6DC8EC',
          '#9270CA',
          '#FF9D4D',
          '#269A99',
          '#FF99C3',
        ],
      point: {
        shape: ({ type }) => {
          return type === 'tv' ? 'square' : 'radio' ? 'diamond' : 'social' ? 'hexagon' : 'graphics' ? 'cross' : 'triangle';
        },
        style: ({ year }) => {
          return {
            r: Number(year) % 4 ? 0 : 3, // 4 个数据示一个点标记
          };
        },
      },
    };
    return <Line  {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);

const dateFormat = 'YYYY-MM-DD';
function PriceByMediaDate(props) {
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
      const [data, setData] = React.useState([]);
      useEffect(() => {
      }, [data]);
      useEffect(() => {
        getNotes();
      }, [props.filterDates, props.filterMedia]);


  const getNotes = () => {        
    client.get("api/pricenotesperdate?range={\"start\":\""+props.filterDates[0]+"T00:00:00\",\"end\":\""+props.filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\",\"mediaId\":["+props.filterMedia+"]}").then(res=>{
      setData(res["data"]["data"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVAPrice-"+props.filterDates[0]+" a " + props.filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVAPrice-"+props.filterDates[0]+" a " + props.filterDates[1], pdfOptions:{ w:'280', h:'200' ,  x:5, orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

  const handle = useFullScreenHandle();
  const [fullScreen, setFullScreen] = React.useState(false);
  const toggleFullScreen = () => {
    setFullScreen (!fullScreen);
    
  }
  useEffect(()=>{
    if(fullScreen){
      handle.enter();
      
    } else {
      handle.exit();
    }
  }, [fullScreen])
  const reportChange = useCallback((state, hand) => {
    setFullScreen(state);
  }, [handle]);



    
  return (
    <>
    <FullScreen handle={handle} onChange={reportChange}>


                  <h3>Precio de aparición por fecha {!fullScreen && <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }{fullScreen && <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}{!fullScreen && <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}</h3>
                  <div ref={referencecomp} style={{height: fullScreen? "90%" : "400px"}}>
                  
                   <Graphic data={data}></Graphic>
                   {((downloadJpg>0) || fullScreen) && 
                    <>
                      
                      
                      <Row>
                        <Col span={18}>
                        Precio de tiempo al aire generado automáticamente. <br></br>
                      Rango de fechas: {"Desde: " + props.filterDates[0]+ " hasta "+props.filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                    </>
                  }
                   </div>
                   </FullScreen>  
    </>
  )
}

export default PriceByMediaDate