import React, { useState } from 'react'
import { TabBar, SafeArea } from 'antd-mobile'
import LiveMenu from './Live.js'
import SearchMenu from './Search.js'
import Statistics from './Statistics.js'
import './index.css'
import { useNavigate } from "react-router-dom";
import {
  PlayOutline,
  SearchOutline,
  HistogramOutline,
  CloseOutline,
} from 'antd-mobile-icons'

export default () => {
  const navigate = useNavigate(); 
  function Menu() {
    if (menu==='live') {
      return <LiveMenu />;
    } 
    if (menu==='search'){
    return <SearchMenu />;
  }
    if (menu ==='statistics'){
      return <Statistics/>
    }
    if (menu === 'logout'){
      localStorage.setItem("token", 0);
      localStorage.setItem("logged", 0);
      localStorage.setItem("email", 0 );
      localStorage.setItem("clientId", 0)
      localStorage.setItem("name", 0)
      navigate("/")
    }
  }
  const tabs = [
    {
      key: "live",
      title: 'En vivo',
      icon: <PlayOutline/>
    },
    {
      key: "search",
      title: 'Busqueda',
      icon: <SearchOutline />
      
    },
    {
      key: 'statistics',
      title: 'Estadisticas',
      icon: <HistogramOutline />,
    },
    {
      key: 'logout',
      title: 'Salir',
      icon: <CloseOutline />,
    },
  ]

  const [menu, setMenu] = useState("live")

  return (
    <>
    
      <div  style = {{position: 'fixed', top: 0, width: '100%', textAlign: 'center', background: '#686A6C', zIndex:100}}>
        
        <img  src="./logocva.png" alt="Not Found"></img> <p style={{ color:'white' }}>Hola, {localStorage.getItem("name")} </p>
        <SafeArea position='top'/>
      </div>
      <div style = {{marginTop: 72, marginBottom:50, width: '100%', textAlign: 'center'}}>
         
          {
          Menu()
          
          }
      </div>
      <div style = {{position: 'fixed', bottom: 0, width: '100%' }}>
        <TabBar safeArea={true} defaultActiveKey={"live"} onChange={value => setMenu(value)}>

          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} disabled = {item.disabled}  title={item.title}  style={{backgroundColor: '#b5b3b3'}} />
          ))}
        </TabBar>
      </div>

    </>
  )
}