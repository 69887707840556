import "./Note.css";
import React, { useState, useEffect} from 'react';
import Tone from "./Tone.js";
import Price from "./Price";
import { WhatsAppOutlined, EyeOutlined, SearchOutlined, FilePdfOutlined, CloudDownloadOutlined} from '@ant-design/icons';
import { Avatar, Card, Collapse, Button, Modal, Image, Col, Row} from 'antd';
import ReactPlayer from 'react-player';
import Highlighter from 'react-highlight-words';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import moment from 'moment';
const { Meta } = Card;



const fullNote = ({estimation = 0, title = "Sin titulo", searchText = "", start = "", end = "", description = "", link ="", linkAlt="", linkAlt2="", linkAlt3=""}) =>{

  



  const getTypeLink = (link) =>{
    if(link.includes("mp4")){
      return "Ver video: "
    } else if (link.includes("mp3")){
      return "Escuchar audio: "
    } else if (link.includes("pdf")){
      return "Ver pdf: "
    } else if (link.includes(".")){
      return "Visitar recurso: "
    } else if (link.includes(".jpg")){
      return (<img src={link} alt="Imagen de la nota" ></img>)
    } else {
      return "No hay mas recursos para esta nota."
    }
  }


  

  const getContentLink=(link) =>{
    if(link.includes("mp4")){
      return (<ReactPlayer url={link} width="100%" controls />)
    } else if (link.includes("mp3")){
      return (<ReactPlayer url={link} width="100%" controls height={50} />)
    } else if (link.includes("pdf")){
      return (<object width="100%" height="800" data={link} type="application/pdf">   </object>)
    } else if (link.includes(".")){
      return (<iframe
        title="Nota original"
        src={link}
        width="100%"
        height="800px"
        />)
    } else {
      return "No hay mas recursos para esta nota."
    }



  }

  const items: CollapseProps['items'] = linkAlt3 ? 

    [
    {
      key: '1',
      label: getTypeLink(link),
      children: getContentLink(link),
    },
    {
      key: '2',
      label: getTypeLink(linkAlt),
      children: getContentLink(linkAlt),
    },
    {
      key: '3',
      label: getTypeLink(linkAlt2),
      children: getContentLink(linkAlt2),
    },
    {
      key: '4',
      label: getTypeLink(linkAlt3),
      children: getContentLink(linkAlt3),
    }]:
    linkAlt2 ? 
      [{
        key: '1',
        label: getTypeLink(link),
        children: getContentLink(link),
      },
      {
        key: '2',
        label: getTypeLink(linkAlt),
        children: getContentLink(linkAlt),
      },
      {
        key: '3',
        label: getTypeLink(linkAlt2),
        children: getContentLink(linkAlt2),
      }]:
      linkAlt ? [
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        },
        {
          key: '2',
          label: getTypeLink(linkAlt),
          children: getContentLink(linkAlt),
        }]:[
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        }]
  ;

  
  return(

    <Card className="custom-card"
    title={title}
    
    > 
      <Collapse items={items} >
      </Collapse>
    <p></p>
    <Meta
      title={<h3>{"Texto completo de la nota: "}</h3>}
      
    />
    <p><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
        autoEscape={true}
        textToHighlight={description}
      /></p>
  </Card>


  );}
  


const getType = (type) =>{
  if(type === "web"){
    return "Web"
  } else if(type === "radio"){
    return "Radio"
  } else if(type === "graphics"){
    return "Gráfica"
  } else if(type === "social"){
    return "Redes Sociales"
  } else if(type === "tv"){
    return "Televisión"
  } else {
    return ""
  }
}


const Note = ({ estimation = 0, id = 0, seconds = 0, media_id=0, country = "", diff_time = null, col = 0, linkAlt ="", searchText="", linkAlt2="", linkAlt3="", web_username = "", type = "Nota: ", price = "0", cm2 = "0", duration = "0", link = "", clientKeys = [], keys = [], date = "", program = "", source = "", loading="true", journalist="Periodista", title="Sin titulo", description="No hay una descripción asociada", start="", end="", avatar="https://xsgames.co/randomusers/avatar.php?g=pixel"}) => {
  const jar = new CookieJar();
  const client = wrapper(axios.create({ jar }));
  client.defaults.baseURL = 'https://san.cvamedios.com/';
  client.defaults.headers.common['Content-Type'] = 'application/json';
  client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
  client.defaults.headers.common['Accept'] = 'application/json';
  client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")

const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

const [open, setOpen] = useState(false);
const showDefaultDrawer = () => {
  setOpen(true);
};
const downloadFile=(file)=>{
  window.open(file, 'Download');
}
const claimClip = (comments, start_time, end_time, media_id, seconds, date) => {
  let data = {
    note_id: id,
    details: comments,
    start_time: start_time,
    end_time: end_time,
    media: media_id,
    duration: seconds,
    date: moment(date).format('YYYY/MM/DD'),
    status: 1,
    clients: parseInt(localStorage.getItem("clientId")),
  }
  
  client.post("api/clipgo", data).then(res=>{
    
    }).catch(err => {
      console.log("ERROR OBTENIENDO DATOS<: " + err)
    });
}
const onClose = () => {
  setOpen(false);
};

return (
  <>
  <Modal title="Nota completa." open={open} onClose={onClose} onOk={onClose} onCancel={onClose} width="1000px">
     {fullNote({title, searchText, description, link, linkAlt, linkAlt2, linkAlt3})}
  </Modal>
      

  <Card
    style={{ margin: "5px",  
      backgroundColor: "#EAECEE",
      borderRadius: "16px", 
      marginRight: "3px", 
      boxShadow: "2px 2px 14px 2px rgba(208, 216, 243, 0.6)", 
      width: (col == 0 ? (width<1800 ? "46%" : width < 2000 ? "30%" : "18%") : col == 1 ? '100%' : col == 2 ? '48%' : col == 3 ? "31%" : col==4 ? '22.5%' : '18%'), 
      height:(col == 0 ? "630px" : col == 2 ? "":col == 3 ? "":col == 4 ? "":""),
      minHeight: (col == 1 ? "330px" : col == 2 ? "500px": col == 3 ? "520px":col == 4 ? "500px":"530px")
      }}
    cover={
      col!=1 &&
      <div class="image600">
          <img
            alt="fuente"
            src={source}
            width={85}
            className="image600 center"
          />
      </div> 
    }

    title={ type !== "" ? getType(type)+": "+ title : title
                 }
    loading={loading}
    actions={ link !=="" && (type!==2 || type!== 4) ? 
      [

        <Button onClick={showDefaultDrawer}>
        <SearchOutlined/> Ver más
      </Button>,
        <Button onClick={()=>downloadFile(link)}> <EyeOutlined /> Visitar</Button>,
        <Button disabled={true}><FilePdfOutlined key="pdf" /></Button>,
        <Button disabled={true}><WhatsAppOutlined key="share" /></Button>,
        
    ]
      
      :
      [

        <Button onClick={showDefaultDrawer}>
        <SearchOutlined/> Ver más
      </Button>,
        <Button onClick={() => {claimClip (description, start, end, media_id, seconds, date)}}> <CloudDownloadOutlined /> Pedir clip </Button>,
        <Button disabled={true}><FilePdfOutlined key="pdf" /></Button>,
        <Button disabled={true}><WhatsAppOutlined key="share" /></Button>,
        
    ]}
  > 
    {

      col==1 &&


    
    <Row>
      <Col span={2}>
          <div >
              <img
                alt="fuente"
                src={source}
                style={{width:"79%"}}
                class=""
              />
          </div> 
      </Col>
      <Col span={6}>
        <p></p>
            <Meta
            
            title={type =="radio" || type=="tv"?  program: ""}
            description = {type !== "social" ? <Price country = {country} diff_time={diff_time} duration = {duration} cm2 = {cm2} price = {price} date = {date} type={type} start={start} end={end}/> : type=="web" ? <Price country = {country} duration = {0} cm2 = {0} price = {price} date = {date}/> : <Price country = {country} duration = {0} cm2 = {0} price = {price} date = {date}/> } 

          />
          <Meta
            avatar={<Avatar src={avatar} />}
            title={journalist}
            

          />
      </Col>
      <Col span={13}>
            <p className="note-description"><Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
              autoEscape={true}
              textToHighlight={description}
            /></p>
      </Col>
      <Col offset={1} span={2}>
        <p></p>
      { keys.filter((item,index) => (index < 4)).map(key => (
              clientKeys.split(",").includes(key.id.toString()) ? <Tone  name = {key.name} color = {key.symbol ? key.symbol : "="}/>: ""
              )) }
      </Col>
    </Row>
    } 
    {
      col!=1 && 
    <>
    
      <Meta
            
            title={type =="radio" || type=="tv"?  program: ""}
            description = {type !== "social" ? <Price estimation = {estimation} country = {country} diff_time={diff_time} duration = {duration} cm2 = {cm2} price = {price} date = {date} type={type} start={start} end={end}/> : type=="web" ? <Price country = {country} duration = {0} cm2 = {0} price = {price} date = {date}/> : <Price country = {country} duration = {0} cm2 = {0} price = {price} date = {date}/> } 

          />
          <Meta
            avatar={<Avatar src={avatar} />}
            title={journalist}
            

          />
      <p className="note-description"><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
        autoEscape={true}
        textToHighlight={description}
      /></p>
    <p></p>
    { keys.filter((item,index) => (index < 4)).map(key => (
        clientKeys.split(",").includes(key.id.toString()) ? <Tone  name = {key.name} color = {key.symbol ? key.symbol : "="}/>: ""
        )) }
    <br></br>
    <br></br>
    </>


    }
    
    
  </Card>
  
  </>
)}
;

export default Note;