import React from 'react'
import { format } from 'date-fns';
import moment from 'moment';
import {DollarOutlined, CalendarOutlined, ClockCircleOutlined} from '@ant-design/icons';
function Tone(props) {
    const date = props.date
    //console.log(props.cm2)
    function currencyFormat(num, country) {
      var currency = country=="Argentina" ? '$' : country=="Paraguay" ? "Gs." : "$";
      return currency + (num ? (num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) : "0")
   }
function toCVADate(date){
  return moment(date).format('DD/MM/yyyy')

}


  return (
    <React.Fragment>
      <div style={{color: 'black'}}>
          <DollarOutlined/> { (props.price === (null) ? "$0" : props.cm2 === "0" ? props.duration === "0"  ? (props.price>=1 ? (props.country=="Paraguay" ? currencyFormat(props.estimation, props.country) : currencyFormat(props.estimation, props.country)) : "Sin precio asignado")  : currencyFormat(props.estimation, props.country) : currencyFormat(props.estimation, props.country)) }
        <br></br> 
        <CalendarOutlined />  { toCVADate(date) } <br></br><ClockCircleOutlined /> { props.start && props.start.includes(":") && props.end && props.end.includes(":") ? (props.start + " a " + props.end + (props.diff_time ? ' ('+props.diff_time+')':"")) : date[1] }
      </div>
    </React.Fragment>
  )
}

export default Tone