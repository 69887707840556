import React, {useEffect, useRef, memo, useCallback} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined, GlobalOutlined, BorderlessTableOutlined, VideoCameraOutlined, SoundOutlined, FilePdfOutlined} from '@ant-design/icons'
import { DatePicker} from 'antd';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import dayjs from 'dayjs';
import { isEqual } from "lodash-es";
const Graphic: React.FC<any> = memo(
  ({ data, onReady }) => {

    function renderStatistic(containerWidth, text, style) {
      const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
      const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
  
      let scale = 1;
  
      if (containerWidth < textWidth) {
        scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
      }
  
      const textStyleStr = `width:${containerWidth}px;`;
      return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'name',
      radius: 0.8,
      innerRadius: 0.65,
      meta: {
        value: {
          formatter: (v) => `${v} Notas`,
        },
      },
      label: {
        animate: true,
        type: 'spider',
        position:'right',
        labelHeight: 24,
        content: (datum) => {
          
          return datum ?  datum.name + '\n' + datum.value : '';

        }
      },
      statistic: {
        title: {
          offsetY: -4,
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            const text = datum ? datum.name: 'Total';
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
        content: {
          offsetY: 4,
          style: {
            fontSize: '32px',
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const text = datum ? `Notas: ${datum.value}` : `Notas: ${data.reduce((r, d) => r + d.value, 0)}`;
            return renderStatistic(width, text, {
              fontSize: 32,
            });
          },
        },
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
        {
          type: 'pie-statistic-active',
        },
      ],
    };
    return <Pie {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
function NotesByMedia() {
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
  const [data, setData] = React.useState([]);
  useEffect(() => {
    console.log(data);
  }, [data]);
  const [filterDates, setFilterDates] = React.useState(["2020/01/01","2025/12/31"]);
  useEffect(() => {
    getNotes();
  }, [filterDates]);


  const getNotes = () => {        
    client.get("api/notespertype?range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\"}").then(res=>{
      setData(res["data"]["data"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVATipoMedio-"+filterDates[0]+" a " + filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVATipoMedio-"+filterDates[0]+" a " + filterDates[1], pdfOptions:{ w:'300', h:'200' ,  x:5, orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

    function onSelectDate(date, dateString) {
      console.log("changing Dates")
      setFilterDates([dateString[0],dateString[1]]);
    }

    const handle = useFullScreenHandle();
    const [fullScreen, setFullScreen] = React.useState(false);
    const toggleFullScreen = () => {
      setFullScreen (!fullScreen);
      
    }
    useEffect(()=>{
      if(fullScreen){
        handle.enter();
        
      } else {
        handle.exit();
      }
    }, [fullScreen])
    const reportChange = useCallback((state, hand) => {
      setFullScreen(state);
    }, [handle]);
  
    const [graphic, setGraphic] = React.useState(true);

      const toggleGraphic = () => {
          setGraphic(!graphic);
      }
    
      const columns = [
        
        {
          title: 'Tipo de medio',
          dataIndex: 'name',
          key: 'media',
          render: (text) =>  {let val = text==="Web" ? <><GlobalOutlined />{ text}</> : text === "Redes sociales" ? <><BorderlessTableOutlined />{ text}</>: text === "TV" ? <><VideoCameraOutlined /> { text}</> :text === "Radio" ? <><SoundOutlined /> { text}</>: <><FilePdfOutlined /> { text}</>  ; return val;},
          sorter: (a, b) => (!a.name || !b.name) ? false:a.name.localeCompare(b.name),
          width: '5%',
        },
        {
          title: 'Cantidad de notas',
          dataIndex: 'value',
          key: 'value',
          render: (text) =>  {return text},
          
          width: '5%',
          sorter: (a, b) => a.value - b.value,
        },
        
      ];

  return (
    <>    
            <FullScreen handle={handle} onChange={reportChange}>
                  <h2>Mis notas totales en cada tipo de medio {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>} </h2>
                  filtra por fechas: <RangePicker
                          onChange={onSelectDate}
                          defaultValue={[dayjs('2020/01/01 ', dateFormat), dayjs('2025/12/31', dateFormat)]}
                          format={dateFormat} />


                  <div ref={referencecomp} style={{height: fullScreen? "90%" : "450px"}}>
                  {
                    graphic &&  <Graphic data={data} />

                  }
                  { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ position: ["bottomLeft"] }} 
                        size={"middle"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record) => record.name}
                        scroll={{ y: 350 }}
                        />
                    
                    </>
                  }
                  {((downloadJpg>0) || fullScreen) && 
                   <>

                      <Row>
                        <Col span={16}>
                          Cantidad de notas por tipo generada automáticamente. <br></br>
                          Rango de fechas: {"Desde: " + filterDates[0]+ " hasta "+filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                     
                   </>
                 }
                  </div>

                  </FullScreen>
                  
    </>
  )
}

export default NotesByMedia