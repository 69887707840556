import React from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";


const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


const jar = new CookieJar();
const client = wrapper(axios.create({ jar }));
client.defaults.baseURL = 'https://san.cvamedios.com/';
client.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
client.defaults.headers.common['Accept'] = 'application/json';
client.defaults.withCredentials = true






function LoginForm() {
  const navigate = useNavigate(); 
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
  api.info({
    message: `Credenciales inválidas`,
    description:
    'Vuelve a ingresar tus nombre de usuario y contraseña. Si el problema persiste por favor comunícate con nosotros.',
    placement,
  });
};
  return(
      
  <Form justify="center"
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={
      async (values) => {
        client.get('sanctum/csrf-cookie')
        .then(() => {
          client.post('api/login', values)
            .then(res => {
              client.defaults.headers.common.Authorization=  'Bearer ' + res.data["content"]["access_token"];
              localStorage.setItem("token", res.data["content"]["access_token"]);
              localStorage.setItem("logged", 1);
              localStorage.setItem("keys", res.data["content"]["keys"])
              localStorage.setItem("email", res.data["content"]["user_email"] );
              localStorage.setItem("keywords", res.data["content"]["keywords"])
              client.get('/api/clientsEmail?filters={"emails":"'+localStorage.getItem("email")+'"}').then(res=>{
                localStorage.setItem("clientId", res.data["message"]["rows"][0]["id"])
                
                localStorage.setItem("iabasic", res.data["message"]["rows"][0]["iabasic"])
                localStorage.setItem("name", res.data["message"]["rows"][0]["name"])
                localStorage.setItem("keysNames", res.data["message"]["rows"][0]["keys"].map((value)=> value.name))
                localStorage.setItem("iafull", res.data["message"]["rows"][0]["iafull"])
                
                navigate("/home")
              })
            })
            .catch(err => {
              console.log("err")
              localStorage.setItem("logged", 0)
              openNotification('top')
            });

          });
      localStorage.getItem("logged")===1 ? navigate("/home") : navigate("/");
    }
  }
    onFinishFailed={onFinishFailed()}
    autoComplete="on"
  >
    {contextHolder}
    <CookieConsent>Este sitio web utiliza cookies para mejorar la experiencia del usuario.</CookieConsent>


    <Form.Item
      label="Username"
      name="username"
      rules={[
        {
          required: true,
          message: 'Ingresa tu nombre de usuario',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: 'Ingresa tu contraseña',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Checkbox>Recuerdarme en este equipo</Checkbox>
    </Form.Item>
    
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Ingresar
      </Button>
    </Form.Item>
  </Form>
  

  
    );
}
export default LoginForm;
