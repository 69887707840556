import React, {useEffect} from 'react'
import { Col, Row, Divider, Select, Space, Breadcrumb} from 'antd';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import NotesByMedia from './NotesByMedia';
import NotesByMediaId from './NotesByMediaId';
import NotesByJournalist from './NotesByJournalist';
import WordCloudNotes from './wordCloud';
import NotesByMediaDate from './NotesByMediaDate';
import PriceByMediaDate from './PriceByMediaDate';
import NotesBySubjectId from './NotesBySubjectId';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import NotesByAssesment from './NotesByAssesment';
import TimeByMediaDate from './TimeByMediaDate';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function Statistics() {
      const jar = new CookieJar();
      
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
      const [filterDates, setFilterDates] = React.useState(["2020/01/01","2025/12/31"]);
      const [mediaList, setMediaList] = React.useState([]);
      const [rangeDate, setRangeDate] = React.useState(true);
      const [typeDate, setTypeDate] = React.useState("range");
      const [cols, setCols] = React.useState(2);
      const [filterMedia, setFilterMedia] = React.useState([]);
      useEffect(() => {
        getMedias();
        getSubjects();
        getKeys();
      }, []);
      
      const getMedias = () => {        
        client.get("api/notespermedia?range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\"}").then(res=>{
          setMediaList(res["data"]["data"]);
          }).catch(err => {
            console.log("ERROR OBTENIENDO DATOS<: " + err)
          });
        }
      function getSubjects(){

      }
      function getKeys(){

      }

      function mediasUsed(values){
        setMediaList(values);
        
      }


      function onSelectDate(date, dateString) {
        
        if(rangeDate){
          setFilterDates([dateString[0],dateString[1]]);
        } else if(typeDate=="week"){
          setFilterDates([date.day(-1).$d.toISOString().split("T")[0],date.day(5).$d.toISOString().split("T")[0]] );
        } else if(typeDate=="month"){
          setFilterDates([date.date(0).$d.toISOString().split("T")[0],date.date(0).add(1,'month').subtract(1,"day").$d.toISOString().split("T")[0]] );
        } else if(typeDate=="quarter"){
          setFilterDates([date.date(0).$d.toISOString().split("T")[0],date.date(0).add(3,'month').subtract(1,"day").$d.toISOString().split("T")[0]] );
        } else if(typeDate=="year"){
          setFilterDates([date.date(0).$d.toISOString().split("T")[0],date.date(0).add(1,'year').subtract(1,"day").$d.toISOString().split("T")[0]] );
        }
        
      };
      useEffect(() => {
        getMedias();
        getSubjects();
        getKeys();
      }, [filterDates]);

      function typeDateHandle(value){
        if(value == "range"){
          setRangeDate(true)
        } else {
          setTypeDate(value)
          setRangeDate(false)
        }
        getMedias();
      }

      function mediaListChange(media, e) {
        setFilterMedia(media);
      }
      const changeCols = (value) =>{
        setCols(value)
      }

  return (
    <>  <Row>
          <Col span={10}>
          <p>Tamaño de gráficos <Select
                        mode="single"
                        style={{ width: '25%' }}
                        placeholder="Tamaño de los gráficos"
                        defaultValue={[]}
                        onChange={changeCols}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        
                        <Option value="1" label="Grande">
                          <Space>
                            Grande
                          </Space>
                        </Option>
                        <Option value="2" label="Medio">
                          <Space>
                            Medio
                          </Space>
                        </Option>
                        <Option value="3" label="Pequeño">
                          <Space>
                            Pequeño
                          </Space>
                        </Option>
                        
                      </Select></p>
          </Col>
          <Col span={6}>
            <h1 style={{textAlign:"center"}}>Estadísticas generales</h1>
          </Col>
        </Row>
      <Row gutter={[25, 26]}>
        <Col span={cols<3 ? 24/cols: 12}>
                  <NotesByMedia/>
        </Col>
        <Col span={cols<3 ? 24/cols: 12}>
                  <NotesByMediaId medias = {mediasUsed}/>
        </Col>
        
        
      </Row>
      <Row>
      <Col span={24}>
        <div style={{height:"100%"}}>
          <NotesByJournalist/>  
        </div>
        </Col>
       <Col span={24}>
       <div style={{height:"700px"}}>
            <NotesBySubjectId filterDates={filterDates} filterMedia={filterMedia}/>
        </div>
    </Col>
      </Row>
      <Divider/>
      <Row>
          <Col span={24}>
            <h1 style={{textAlign:"center"}}>Estadísticas específicas</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h2 style={{textAlign:"center"}}>Selección de filtros</h2>
            <Breadcrumb
            separator="       |       " 
              style={{
                margin: '30px 45px',
              }}
            >
                  <Breadcrumb.Item><Space direction="horizontal" size={12} wrap={true} >
                          <Select
                                mode="single"
                                style={{ width: '360px' }}
                                placeholder="Rango de tiempo"
                                defaultValue={[]}
                                onChange={typeDateHandle}
                                optionLabelProp="label"
                                optionFilterProp="label"
                              >
                                <Option value="range" label="Rango de fechas">
                                  <Space>
                                    Rango de fechas
                                  </Space>
                                </Option>
                                <Option value="week" label="Semanal">
                                  <Space>
                                    Semanal
                                  </Space>
                                </Option>
                                <Option value="month" label="Mensual">
                                  <Space>
                                    Mensual
                                  </Space>
                                </Option>
                                <Option value="quarter" label="Trimestral">
                                  <Space>
                                    Trimestral
                                  </Space>
                                </Option>
                               {/*} <Option value="year" label="Anual">
                                  <Space>
                                    Anual
                                  </Space>
            </Option>*/}
                                
                              </Select>
                      </Space>
                  
                      </Breadcrumb.Item>
                  <Breadcrumb.Item><Space direction="horizontal" size={12} wrap={true} >
                          {rangeDate && <>
                          Rango de fechas: <RangePicker
                                              onChange={onSelectDate}
                                              defaultValue={[dayjs('2020/01/01 ', dateFormat), dayjs('2025/12/31', dateFormat)]}
                                              format={dateFormat} /> </>}
                          {!rangeDate && <>
                          Periodo de tiempo: <DatePicker onChange={onSelectDate} picker={typeDate} /></>}
                      </Space>
                  
                      </Breadcrumb.Item>
                      <Breadcrumb.Item><Space direction="horizontal" size={12} wrap={true} >
                          Medio: <Select
                                    mode="multiple"
                                    style={{ width: '360px' }}
                                    placeholder="Selecciona los medios"
                                    defaultValue={[]}
                                    onChange={mediaListChange}
                                    optionLabelProp="label"
                                    optionFilterProp="label"
                                    >
                                      {
                                        mediaList.map(data => (
                                          <Option value={data.id} label={data.name}>
                                            <Space>
                                              {data.name + " - "+ data.value+" Notas."}
                                            </Space>

                                          </Option>
                                        ))
                                      }
                      
                                    </Select>
                                    </Space>
                  </Breadcrumb.Item>
                  </Breadcrumb>
            </Col>
        </Row>
      <Divider/>
      
      <Row gutter={[25, 26]}>
        <Col span={24/cols}>
            <NotesByMediaDate filterDates={filterDates} filterMedia={filterMedia}/>
        </Col>
        {cols==1 && <Divider/>}
        <Col span={24/cols}>
                
            <WordCloudNotes col={cols} filterDates={filterDates} filterMedia={filterMedia}/>
        </Col>
        
      
      {cols==2 && <Divider/>}
      {cols==1 && <Divider/>}
        <Col span={24/cols}>
            <TimeByMediaDate filterDates={filterDates} filterMedia={filterMedia}/>
            
        </Col>
        {cols==3 && <Divider/>}
        {cols==1 && <Divider/>}
        <Col span={24/cols}>
            <NotesByAssesment filterDates={filterDates} filterMedia={filterMedia}/>
        </Col>
        {cols==2 && <Divider/>}
        {cols==1 && <Divider/>}
        <Col span={24/cols}>
            <PriceByMediaDate filterDates={filterDates} filterMedia={filterMedia}/>
        </Col>
        
        
      </Row>
    </>
  )
}

export default Statistics