import React from 'react';
import { Button} from 'antd';
import { useNavigate } from "react-router-dom";




function LogOut() {
  const navigate = useNavigate(); 
  const LogoutClick = (event) => {
    localStorage.setItem("token", 0);
    localStorage.setItem("logged", 0)
    navigate("/")
};
  return(
      <Button type="primary" danger onClick={LogoutClick}>
        Cerrar sesion
      </Button>  
    );
}
export default LogOut;