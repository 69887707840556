import React, {useEffect, useRef, memo, useCallback} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined,GlobalOutlined, BorderlessTableOutlined, VideoCameraOutlined, SoundOutlined, FilePdfOutlined} from '@ant-design/icons'
import { DatePicker} from 'antd';
import { Column} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import dayjs from 'dayjs';
import { isEqual } from "lodash-es";
const Graphic: React.FC<any> = memo(
  ({ data, onReady }) => {
    const calcAverageValue = (data, type) => {
      const items = data.filter((d) => d.type === type);
      return items.length ? items.reduce((a, b) => a + b.value, 0)  : '-';
    };
    const config = {
      data,
      
      xField: 'name',
      yField: 'value',
      seriesField: 'type',
      legend: {
        position: 'right-top',
        offsetX: 2,
        title: {
          text: 'Notas totales',
          spacing: 8,
        },
        itemName: {
          formatter: (text, item) => {
            return (item.name =="tv" ? "TV": item.name =="radio" ? "Radio": item.name =="graphics" ? "Gráfica": item.name =="social" ? "Redes S.": "Web")+": ";
          },
          style: (item, index) => {
            return {
              fill: item.name == "-" ? 'red' : item.name == "+" ? 'green': 'gray',
            };
          },
        },
        itemValue: {
          formatter: (text, item) => {
            return  new Intl.NumberFormat('en-US', {
                
            }).format(calcAverageValue(data, item.value));
          },
          style: (item, index) => {
            return {
              fill: item.value == 0 ? 'red' : 'gray',
            };
          },
        },
      },
      yAxis:{
        title: {
          text: 'Cantidad de notas',
          style: {
            fontSize: 16,
          },
        },
      },
      
      label: {
        content: (originData) => {
          const val = parseInt(originData.value);
          return val
        },
        offset: 10,
      },
      xAxis: {
        title: {
          text: 'Medio de comunicación',
          style: {
            fontSize: 16,
          },
        },
        label: {
          layout: [
            {
              type: 'hide-overlap',
            },
          ],
          autoHide: false,
          autoRotate: true,
        },
      },
    };
    return <Column {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
function NotesByMediaId(props) {
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
  const [data, setData] = React.useState([]);
  useEffect(() => {
  }, [data]);
  const [filterDates, setFilterDates] = React.useState(["2020/01/01","2025/12/31"]);
  useEffect(() => {
    getNotes();
  }, [filterDates]);


  const getNotes = () => {        
    client.get("api/notespermedia?range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\"}").then(res=>{
      setData(res["data"]["data"]);
      props.medias(res["data"]["data"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVAMediosId-"+filterDates[0]+" a " + filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVAMediosId-"+filterDates[0]+" a " + filterDates[1], pdfOptions:{ w:'280', h:'200' ,  orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

    function onSelectDate(date, dateString) {
      setFilterDates([dateString[0],dateString[1]]);
    }

    const handle = useFullScreenHandle();
    const [fullScreen, setFullScreen] = React.useState(false);
    const toggleFullScreen = () => {
      setFullScreen (!fullScreen);
      
    }
    useEffect(()=>{
      if(fullScreen){
        handle.enter();
        
      } else {
        handle.exit();
      }
    }, [fullScreen])
    const reportChange = useCallback((state, hand) => {
      setFullScreen(state);
    }, [handle]);
  

    const [graphic, setGraphic] = React.useState(true);

      const toggleGraphic = () => {
          setGraphic(!graphic);
      }
    
      const columns = [
        {
          title: 'Tipo de medio',
          dataIndex: 'type',
          key: 'media',
          render: (text) =>  {let val = text==="web" ? <><GlobalOutlined />{ " Web"}</> : text === "social" ? <><BorderlessTableOutlined />{ " Redes sociales"}</>: text === "tv" ? <><VideoCameraOutlined /> { " Televisión"}</> :text === "radio" ? <><SoundOutlined /> { " Radio"}</>: <><FilePdfOutlined /> { " Gráfica"}</>  ; return val;},
          sorter: (a, b) => (!a.type || !b.type) ? false:a.type.localeCompare(b.type),
          width: '5%',
          filters: [
            {
              text: 'Televisión',
              value: 'tv',
            },
            {
                text: 'Radio',
                value: 'radio',
            },
            {
                text: 'Web',
                value: 'web',
            },
            {
                text: 'Gráfica',
                value: 'graphics',
            },
            {
                text: 'Redes Sociales',
                value: 'social',
            }
          ],
          onFilter: (value, record) => (record.type && record.value>0?record.type.includes(value):false),
        },
        {
          title: 'Medio',
          dataIndex: 'name',
          key: 'media',
          render: (text) =>  {return text},
          
          width: '5%',
              sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Cantidad de notas',
          dataIndex: 'value',
          key: 'value',
          render: (text) =>  {return text},
          
          width: '5%',
          sorter: (a, b) => a.value - b.value,
        },
        
      ];
  
  return (
    <>
              <FullScreen handle={handle} onChange={reportChange}>
                  <h2>Participación de cada medio en mis notas {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>}</h2> <RangePicker
                          onChange={onSelectDate}
                          defaultValue={[dayjs('2020/01/01 ', dateFormat), dayjs('2025/12/31', dateFormat)]}
                          format={dateFormat} />
                  
                  <div ref={referencecomp} style={{height: fullScreen? "90%" : "450px"}}>
                  {
                    graphic &&  <Graphic data={data} />

                  }
                  { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ position: ["bottomLeft"] }} 
                        size={"middle"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record) => record.name+record.type+record.value}
                        scroll={{ y: 350 }}
                        />
                    
                    </>
                  }
                  {((downloadJpg>0) || fullScreen) && 
                   <>

                      <Row>
                        <Col span={16}>
                          Cantidad de notas por medio generada automáticamente. <br></br>
                     Rango de fechas: {"Desde: " + filterDates[0]+ " hasta "+filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                     
                   </>
                 }
                  </div>

                  </FullScreen>
                  
    </>
  )
}

export default NotesByMediaId