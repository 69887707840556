import React, {useEffect} from 'react';
import { Tabs, DatePicker, Input, Select, Space, Button, Image } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { SearchOutlined  } from '@ant-design/icons';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import MyClipStore from './MyClipStore';
import MyClipList from './MyClipList';
import Highlighter from 'react-highlight-words';
import MyClipQuery from './MyClipQuery';
const pickerFormat = 'YYYY/MM/DD';
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;


function MyClips(props) {
    const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
    const [filterDates, setFilterDates] = React.useState([moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")]);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [filterMedias, setFilterMedias] = React.useState("");
    const [currentLoading, setCurrentLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0)
  const [mediaData, setMediaData] = React.useState([]) 
    const [currentData, setCurrentData] = React.useState([]);
    
    useEffect(() => {
        getMediaData();
      }, []);
      
function onSelectDate(date, dateString) {
    setFilterDates([dateString[0],dateString[1]]);
  }
  const handleChangeMedia = (values) => {
    
    setFilterMedias(values);
  };
  const getMediaData = () => {        
    client.get("api/media?limit=1000&type_notes=radio").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const claimClip = (datum) => { 
      console.log(datum);
      let data = {
        details: datum.comments,
        start_time: moment(datum.date).format("HH:mm:ss"),
        end_time: moment(datum.date).add(119, 'seconds').format("HH:mm:ss"),
        media: datum.media_id,
        duration: 119,
        date: moment(datum.date).format('YYYY/MM/DD'),
        status: 1,
      }
      console.log(data);
      client.post("api/clipgo", data).then(res=>{
        
        }).catch(err => {
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
      }


  const searchNotes = () => {
    setCurrentLoading(true);
    client.get("api/nnbridge?limit=2000&media_id="+(filterMedias?filterMedias:"")+"&search="+filterSearch+"&start="+filterDates[0]+" 00:00:00&end="+filterDates[1]+" 23:59:59&order=desc").then(res=>{
      setCurrentData(res["data"]["data"]["rows"]);
      setTotal(res["data"]["data"]["total"])
      setCurrentLoading(false);
      }).catch(err => {
        setCurrentLoading(false);
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }



return (
<>
<Space direction="horizontal" size={24} wrap={true}>

    <Tabs
    defaultActiveKey="1"
    items={[
      {
        label: 'Mis clips',
        key: '1',
        children: <MyClipList/>,
      },
      {
        label: 'Clips Disponibles',
        key: '2',
        children: <MyClipStore/>
      },
      {
        label: 'Solicitar Clip',
        key: '3',
        children: <MyClipQuery/>,
      },
    ]}
  />


                      </Space>

</>
)}
export default MyClips;