import React, { useEffect } from 'react';
import { Button, Checkbox, Input, notification, Statistic} from 'antd';
import { Form, Card, Divider, Collapse, Toast, ConfigProvider } from 'antd-mobile';
import CountUp from 'react-countup';
import axios from 'axios';
import './Login.css';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import { useNavigate } from "react-router-dom";
import esES from 'antd-mobile/es/locales/es-ES'
const formatter = (value) => <CountUp end={value} separator="," />;

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


const jar = new CookieJar();
const client = wrapper(axios.create({ jar }));
client.defaults.baseURL = 'https://san.cvamedios.com/';
client.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
client.defaults.headers.common['Accept'] = 'application/json';
client.defaults.withCredentials = true






function Login() {
  const navigate = useNavigate(); 
  const [api, contextHolder] = notification.useNotification();
  const [loginButton, setloginButton] = React.useState(false)
  const [data, setData] = React.useState([0,0,0,0])
    useEffect(() => {
        axios.get("https://san.cvamedios.com/api/general-stats").then((response) => {
        
        setData([response.data.canales, response.data.notas, response.data.programas, response.documents]);
        console.log(response)
      }); },[]);

  const openNotification = (placement) => {
  api.info({
    message: `Credenciales inválidas`,
    description:
    'Vuelve a ingresar tus nombre de usuario y contraseña. Si el problema persiste por favor comunícate con nosotros.',
    placement,
  });
};
  return(
    <ConfigProvider locale={esES}>
    <section className="App" style={{ 
      background: '#686A6C',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
    <img alt="No disponible" src="logocva.png" style={{ 
        width: "80%",
      }}></img>
        
    <Form.Header>Ingreso de usuarios.</Form.Header>
    
  <Form justify="center"
    name="basic"
    
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={
      async (values) => {
        setloginButton(true)
        client.get('sanctum/csrf-cookie')
        .then(() => {
          client.post('api/login', values)
            .then(res => {
              client.defaults.headers.common.Authorization=  'Bearer ' + res.data["content"]["access_token"];
              localStorage.setItem("token", res.data["content"]["access_token"]);
              localStorage.setItem("logged", 1);
              localStorage.setItem("keys", res.data["content"]["keys"])
              localStorage.setItem("email", res.data["content"]["user_email"] );
              client.get('/api/clientsEmail?filters={"emails":"'+localStorage.getItem("email")+'"}').then(res=>{
                localStorage.setItem("clientId", res.data["message"]["rows"][0]["id"])
                localStorage.setItem("name", res.data["message"]["rows"][0]["name"])
                navigate("/index")
              })
            })
            .catch(err => {
              console.log("err")
              localStorage.setItem("logged", 0)
              openNotification('top')
              setloginButton(false)
            });

          });
      localStorage.getItem("logged")===1 ? navigate("/index") : navigate("/");
    }
  }
    onFinishFailed={onFinishFailed()}
    autoComplete="on"
  >
    {contextHolder}
    

    <Form.Item
      label="Nombre de usuario"
      name="username"
      help='Nombre asignado por CVAMedios'
      rules={[
        {
          required: true,
          message: 'Ingresa tu nombre de usuario',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Contraseña"
      name="password"
      help='Si olvidaste tu contraseña debes contactarte con nosotros'
      rules={[
        {
          required: true,
          message: 'Ingresa tu contraseña',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Checkbox>Recuerdarme en este dispositivo</Checkbox>
    </Form.Item>
    
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      
      <Button
            htmlType="submit"
            type="primary"
            loading={loginButton}
            onClick={() =>
                Toast.show({
                    icon: 'loading',
                  content: 'Estamos validando tu sesión con nuestro servidor.',
                  afterClose: () => {
                    console.log('after')
                  },
                }) }
          >
            Ingresar
          </Button>
    </Form.Item>
  </Form>

  <Divider orientation="left">Conoce nuestros datos disponibles:</Divider>

  <Collapse defaultActiveKey={['1','2']}>
          <Collapse.Panel key='1' title='Cantidad de notas procesadas'>
          <Card bordered={false}>
                        <Statistic
                        formatter={formatter}
                        value={data[1]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Notas"
                        />
                    </Card>
          </Collapse.Panel>
          <Collapse.Panel key='2' title='Cantidad de canales disponibles'>
          <Card bordered={false}>
                        <Statistic
                        formatter={formatter}
                        value={data[0]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Canales"
                        />
                    </Card>
          </Collapse.Panel>
          <Collapse.Panel key='3' title='Cantidad de programas disponibles'>
          <Card bordered={false}>
                        <Statistic
                        formatter={formatter}
                        value={data[2]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Programas"
                        />
                    </Card>
          </Collapse.Panel>
          <Collapse.Panel key='4' title='Cantidad de documentos disponibles'>
          <Card bordered={false}>
                        <Statistic
                        formatter={formatter}
                        value={1000}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        prefix={"+"}
                        suffix="Documentos"
                        />
                    </Card>
          </Collapse.Panel>
        </Collapse>

  </section>
  </ConfigProvider>

  
    );
}
export default Login;
