
import React, { useState, useEffect} from 'react';
import {Button, Modal, Select,  Space} from 'antd';
import ReactPlayer from 'react-player';
const { Option } = Select;





const LivePlayer = ({livestreams=[]}) => {


const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [liveStreamSelected, setLiveStreamSelected] = React.useState("https://www.youtube.com/watch?v=GEJeecPH8LE")
  const [media_selected, setMediaSelected] = React.useState(false);
  const [heightTotal, setHeightTotal] = React.useState("90%");
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);


return (
  <>
  {
      !media_selected && 
                        <Select
                        allowClear={true}
                        showSearch
                        mode="single"
                        style={{ width: '98%' }}
                        placeholder="Selecciona el stream que quieres ver"
                        defaultValue={[]}
                        onChange={setLiveStreamSelected}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          livestreams.map((data, key) => (
                            <Option value={data.link} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      }
    <ReactPlayer url={liveStreamSelected} width={heightTotal} height={heightTotal} onPlay={()=>{setMediaSelected(true); setHeightTotal("100%")}} onPause={()=>{setMediaSelected(false); setHeightTotal("94%")}} controls />
  
  </>
)}
;

export default LivePlayer;