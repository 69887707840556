import './HomePage.css';
import React, { useEffect } from 'react';
import { QRCode, Popover, Card, Row, Col, Statistic, Divider } from 'antd';
import LoginForm from './Login/LoginForm';
import CountUp from 'react-countup';
import axios from 'axios';

const formatter = (value) => <CountUp end={value} separator="," />;


function App() {
    const [text] = React.useState('wa.me/5493515388993');
    const [data, setData] = React.useState([0,0,0,0])
    useEffect(() => {
    axios.get("https://san.cvamedios.com/api/general-stats").then((response) => {
        
        setData([response.data.canales, response.data.notas, response.data.programas, response.documents]);
        
      });
    
    }, []);

    return (
        <section class="App" style={{ 
            backgroundImage: `url("layer1.jpg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}>
            <Row justify="center" align="top">
                    <Col span={24}>
                        <Popover overlayInnerStyle={{ padding: 0 }} content={<QRCode value={text} bordered={false} />}>
                            <img src={"logocva.png"}  alt="No disponible"/>
                            <h3 class = "Slogan">Data & Análisis</h3>
                        </Popover>
                        
                    </Col>
                    
            </Row>
            <Divider orientation="left"></Divider>
            <Row justify="center" align="middle" gutter={[32, 48]}>
                <Col span={10}>
                    <Card>
                        <LoginForm></LoginForm>
                    </Card>   
                </Col>
                
                
            </Row>
            <Divider orientation="left"></Divider>
            <Row justify="center" align="bottom" gutter={[32, 48]}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                        title="Canales disponibles"
                        formatter={formatter}
                        value={data[0]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Canales"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                        title="Notas procesadas"
                        formatter={formatter}
                        value={data[1]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Notas"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                        title="Programas disponibles"
                        formatter={formatter}
                        value={data[2]}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        suffix="Programas"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                        title="Documentos disponibles"
                        formatter={formatter}
                        value={1000}
                        precision={0}
                        valueStyle={{
                            color: '#ff0404',
                        }}
                        prefix={"+"}
                        suffix="Documentos"
                        />
                    </Card>
                </Col>
            </Row>
                    
                
            </section>
        
    );
}

export default App;