
import React, { useState, useEffect} from 'react';
import { CloudDownloadOutlined, CheckOutlined, EyeOutlined, SearchOutlined, FilePdfOutlined} from '@ant-design/icons';
import { Avatar, Card, Collapse, Button, Modal, Popover, Col, Row, notification} from 'antd';
import ReactPlayer from 'react-player';
import Highlighter from 'react-highlight-words';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
const { Meta } = Card;



const fullNote = ({duration=0, date="", searchText = "", start = "", end = "", description = "", link ="", linkAlt="", linkAlt2="", linkAlt3=""}) =>{

  



  const getTypeLink = (link) =>{
    if(link.includes("mp4")){
      return "Ver video: "
    } else if (link.includes("mp3")){
      return "Escuchar audio: "
    } else if (link.includes("pdf")){
      return "Ver pdf: "
    } else if (link.includes(".")){
      return "Visitar recurso: "
    } else if (link.includes(".jpg")){
      return (<img src={link} alt="Imagen de la nota" ></img>)
    } else {
      return "No hay mas recursos para esta nota."
    }
  }


  

  const getContentLink=(link) =>{
    if(link.includes("mp4")){
      return (<ReactPlayer url={link} width="100%" controls />)
    } else if (link.includes("mp3")){
      return (<ReactPlayer url={link} width="100%" controls height={50} />)
    } else if (link.includes("pdf")){
      return (<object width="100%" height="800" data={link} type="application/pdf">   </object>)
    } else if (link.includes(".")){
      return (<iframe
        title="Nota original"
        src={link}
        width="100%"
        height="800px"
        />)
    } else {
      return "No hay mas recursos para esta nota."
    }



  }

  const items: CollapseProps['items'] = linkAlt3 ? 

    [
    {
      key: '1',
      label: getTypeLink(link),
      children: getContentLink(link),
    },
    {
      key: '2',
      label: getTypeLink(linkAlt),
      children: getContentLink(linkAlt),
    },
    {
      key: '3',
      label: getTypeLink(linkAlt2),
      children: getContentLink(linkAlt2),
    },
    {
      key: '4',
      label: getTypeLink(linkAlt3),
      children: getContentLink(linkAlt3),
    }]:
    linkAlt2 ? 
      [{
        key: '1',
        label: getTypeLink(link),
        children: getContentLink(link),
      },
      {
        key: '2',
        label: getTypeLink(linkAlt),
        children: getContentLink(linkAlt),
      },
      {
        key: '3',
        label: getTypeLink(linkAlt2),
        children: getContentLink(linkAlt2),
      }]:
      linkAlt ? [
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        },
        {
          key: '2',
          label: getTypeLink(linkAlt),
          children: getContentLink(linkAlt),
        }]:[
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        }]
  ;

  
  return(

    <Card className="custom-card"
    title={"Datos del clip"}
    
    > 
        <div> Fecha: {date}</div>
        <div> Tiempo: {start} a {end}</div>
        <div> Duración: {duration} segundos</div>
    <p></p>
    <ReactPlayer url={link} width="98%" height={"90%"} controls />
    <Meta
      title={<h3>{"Texto descriptivo de este clip: "}</h3>}
      
    />
    
    <p><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
        autoEscape={true}
        textToHighlight={description}
      /></p>
  </Card>


  );}
  


const getType = (type) =>{
  if(type === "web"){
    return "Web"
  } else if(type === "radio"){
    return "Radio"
  } else if(type === "graphics"){
    return "Gráfica"
  } else if(type === "social"){
    return "Redes Sociales"
  } else if(type === "tv"){
    return "Televisión"
  } else {
    return ""
  }
}


const Clip = ({ getData=(()=>{}), stat =4, created_at, id = 0, own = 0, country = "", diff_time = null, col = 0, linkAlt ="", searchText="", linkAlt2="", linkAlt3="", web_username = "", type = "Nota: ", price = "0", cm2 = "0", duration = "0", link = "", clientKeys = [], keys = [], date = "", program = "", source = "", loading="true", journalist="Periodista", title="Sin titulo", description="No hay una descripción asociada", start="", end="", avatar="https://xsgames.co/randomusers/avatar.php?g=pixel"}) => {

const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
const downloadFile=(file)=>{
    window.open(file, '_blank');
}
const [open, setOpen] = useState(false);
const showDefaultDrawer = () => {
  setOpen(true);
};
const onClose = () => {
  setOpen(false);
};
const openNotification = () => {
  notification.success({
    type: 'success',
    message: 'Clip adquirido correctamente!',
    description:
      'Encuentra este y otros clips adquiridos por ti en la sección Mis Clips.',
    className: 'custom-class',
    style: {
      width: 600,
    },
  });
};

const getDate = (created_at) => {
    var date = new Date(created_at);
    date.setMonth(date.getMonth()+1);
    var days = Math.round((date.getTime() - (new Date().getTime())) / (1000 * 3600 * 24)) ;

    return date.toISOString().split("T")[0] + " (" + days + " días restantes)";
}

const claimClip = (clip, clientid) => {
  let data = {
    clip_id : clip,
    client_id : clientid,
  }
  client.post("api/clipgobuy", data).then(res=>{
    getData();
    openNotification();
    }).catch(err => {
      console.log("ERROR OBTENIENDO DATOS: " + err)
    });

};


return (
  <>
  <Modal title="Información completa del clip." open={open} onClose={onClose} onOk={onClose} onCancel={onClose} width="1000px">
     {fullNote({duration, date, searchText, start, end, description, link, linkAlt, linkAlt2, linkAlt3})}
  </Modal>
      

  <Card
    style={{ margin: "5px",  
      backgroundColor: "#EAECEE",
      borderRadius: "16px", 
      marginRight: "3px", 
      boxShadow: "2px 2px 14px 2px rgba(208, 216, 243, 0.6)", 
      width: "100%",
      height:(col == 0 ? "630px" : col == 2 ? "":col == 3 ? "":col == 4 ? "":""),
      minHeight: (col == 1 ? "330px" : col == 2 ? "500px": col == 3 ? "520px":col == 4 ? "500px":"530px")
      }}
    cover={
      col!=1 &&
      <div class="image600">
          <img
            alt="fuente"
            src={source}
            width={5}
            className="image600 center"
          />
      </div> 
    }

    title={ (own==1 ? "Fragmento de transmisión adquirida" + ([1,3].includes(stat) ? " - Estamos procesando este clip." : ""):"Fragmento de transmisión disponible")}
    loading={loading}
    actions={ own == 1 ? [

        <Button onClick={showDefaultDrawer}>
        <SearchOutlined/> Información completa
      </Button>,
        <Button onClick={()=>downloadFile(link)}> <CloudDownloadOutlined /> Descargar</Button>,
        <><Button disabled={true} onClick={showDefaultDrawer}><CheckOutlined/> Adquirido</Button></> ,
        
    ]:
        [

        <Button onClick={showDefaultDrawer}>
        <SearchOutlined/> Mas detalles
      </Button>,
        <a href={link} target="_blank" rel="noreferrer"><EyeOutlined /> Previsualizar</a>,
        <><Button onClick={()=>claimClip(id, localStorage.getItem("clientId"))}><CheckOutlined/> Adquirir</Button></> ,
        
    ]}
  > 
    {

      own==0 &&


    
    <Row>
      <Col span={2}>
          <div >
              <img
                alt="fuente"
                src={source}
                style={{width:"80%", height:"auto"}}
                class=""
              />
          </div> 
      </Col>
      <Popover content={description} title="Contenido: " overlayStyle={{
    width: "55vw"
  }}>
      <Col offset={1} span={16}>
          
            <p className="note-description"><Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
              autoEscape={true}
              textToHighlight={description}
            /></p>
            
      </Col>
      </Popover>
      <Col offset={1} span={4}>
        <h4>Datos del clip: </h4>
        <div> Fecha: {date}</div>
        <div> Tiempo: {start} a {end}</div>
        <div> Duración: {duration} segundos</div>
        <div> Tiempo de uso restante: </div>
      </Col>
    </Row>
    } 
    {

        own==1 &&



        <Row>
            <Col span={2}>
          <div >
              <img
                alt="fuente"
                src={source}
                style={{width:"80%", height:"auto"}}
                class=""
              />
          </div> 
      </Col>
        <Col span={5}>
        <ReactPlayer url={link} width="98%" height={"90%"} controls />
        </Col>
        <Popover content={description} title="Contenido: " overlayStyle={{
    width: "55vw"
  }}>
        <Col offset={1} span={11}>
            <p className="note-description"><Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
                autoEscape={true}
                textToHighlight={description}
            /></p>
        </Col>
        </Popover>
        <Col offset={1} span={4}>
        <h4>Datos del clip: </h4>
        <div> Fecha: {date}</div>
        <div> Tiempo: {start} a {end}</div>
        <div> Duración: {duration} segundos</div>
        <div> Disponible hasta el: {getDate(created_at)}</div>
        </Col>
        </Row>
        } 
    
    
  </Card>
  
  </>
)}
;

export default Clip;