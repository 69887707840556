import React from 'react';
import { FilePdfOutlined, SoundOutlined, VideoCameraOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import { Layout, Pagination, Divider } from 'antd';
import { PullToRefresh, Collapse, Selector, SearchBar, Button, CalendarPicker } from 'antd-mobile';

import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import Note from "./Note";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './Search.css'

dayjs.extend(customParseFormat);


  
  const SearchMenu = () => {
      const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")

      const now = new Date()
    const [currentData, setCurrentData] = React.useState([]);
    const [filterMedia, setFilterMedia] = React.useState("1,2,3,4")
    const [termSearch, setTermSearch] = React.useState("")
    const [calendarVisible, setCalendarVisible] = React.useState(false)
    const [startDate, setStartDate] = React.useState("2020-01-01")
    const [endDate, setEndDate] = React.useState(now.getFullYear()+"-"+now.getMonth()+"-"+now.getDay())
    
    const getNotes = () => {        
          client.get("api/reports/notes?range={\"start\":\""+startDate+"T00:00:00\",\"end\":\""+endDate+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\",\"media\":["+filterMedia+"],\"search\":\""+termSearch+"\" }").then(res=>{
            setCurrentData(res["data"]["data"]["rows"]);
            }).catch(err => {
              console.log("ERROR OBTENIENDO DATOS<: " + err)
            });
          }

          const items = [
            {
              label: 'Todos',
              value: '1,2,3,4,5',
              icon: <VideoCameraOutlined/>,
            },
            {
              label: 'Gráfica',
              value: '1',
              icon: <FilePdfOutlined />,
            },
            {
              label: 'Radio',
              value: '2',
              icon: <BorderlessTableOutlined />,
            },
            {
              label: 'Web',
              value: '3',
              icon: <SoundOutlined />,
            },
            {
              label: 'Televisión',
              value: '4',
              icon: <VideoCameraOutlined />,
            },
            {
              label: 'Redes sociales',
              value: '5',
              icon: <VideoCameraOutlined />,
            },
          ];
          
    
    
      

      const statusRecord: Record<PullStatus, string> = {
        pulling: 'Desliza para actualizar...',
        canRelease: 'Suelta para actualizar...',
        refreshing: 'Actualizando...',
        complete: 'Completo',
      }
      


    return (
      <>
      <PullToRefresh
            onRefresh={async () => {
              getNotes()
            }}
            renderText={status => {
              return <div>{statusRecord[status]}</div>
            }}
          >

        <Collapse defaultActiveKey={'1'}>
          
          <Collapse.Panel title = 'Filtros de busqueda' key= '1'>
                
                <Selector
                options={items}
                defaultValue={['1,2,3,4,5']}
                onChange={(arr, extend) => {
                  setFilterMedia(arr);
                  }
                }
              />
          
          <Divider>Busca por palabra</Divider>
                <SearchBar placeholder='Término a buscar' onChange={(value)=> setTermSearch(value)}/>
          <Divider>Escoge las fechas para tu busqueda</Divider>
                <Button
                        onClick={() => {
                        setCalendarVisible(true)
                        }}
                >
                Abrir calendario
                </Button> 
                <CalendarPicker
                        title='Selecciona tu fecha'
                        allowClear={true}
                        confirmText='Confirmar'
                        weekStartsOn='Monday'
                        selectionMode='range'
                        min={new Date("2020/01/01")}
                        defaultValue={now}
                        onConfirm={val => {
                            setStartDate(val[0].getFullYear()+"-"+(val[0].getMonth()+1)+"-"+val[0].getDate())
                            setEndDate(val[1].getFullYear()+"-"+(val[1].getMonth()+1)+"-"+val[1].getDate())
                        }}
                        visible={calendarVisible}
                        onClose={() => {setCalendarVisible(false)

                        }}
                        onMaskClick={() => {setCalendarVisible(false)
                        }}
                        />
                Desde: {startDate}<br></br>
                Hasta: {endDate}
                <Divider></Divider>
                    <Button
                        onClick={() => {
                            getNotes()
                            }}>

                                Buscar
                            </Button>
          </Collapse.Panel>
          </Collapse>
        <Layout>
          
                <div className="notes">{
                  currentData.map(data => (
                  <Note 
                    program={"Programa: "+ (data.program ? data.program.name : "No hay descripción del programa.")}
                    date = {data.date ? data.date.split(".")[0] : data.created_at.split(".")[0]}
                    loading={false}
                    title={data.type_note === "social" ? data.web_username : (data.type_note === "radio" || data.type_note === "tv") ? data.media.name : data.title}
                    journalist={data.journalists? data.journalists[0].name+" "+data.journalists[0].surname : data.web_username? data.web_username: "Sin periodista."}
                    keys = {data.keys}
                    description={data.comments}
                    source={"medios/"+ data.media.id +".png"} 
                    link = {data.link ? data.link:""}
                    price = {data.media.price}
                    cm2 = {data.cm ? data.cm : "0"}
                    duration = {data.duration ? data.duration : "0"}
                    type = {data.type_note ? data.type_note : ""}
                    web_username = {data.web_username}
                    linkAlt = {data.linkAlt ? data.linkAlt : ""}
                    linkAlt2 = {data.linkAlt2 ? data.linkAlt2 : ""}
                    linkAlt3 = {data.linkAlt3 ? data.linkAlt3 : ""}
  
                  />
                ))}
                </div>

                <Pagination defaultCurrent={1} total={20} />
        </Layout>
        </PullToRefresh>
      </>
    );
  };
  
  export default SearchMenu;