import React from 'react';
import HomePage from './HomePage.js';
import { BrowserView, MobileView } from 'react-device-detect';
import Login from './Mobile/Login.js'
import esES from 'antd-mobile/es/locales/es-ES.js'
import { ConfigProvider } from "antd-mobile";
function Index() {
  
  return (
    <div>
      <title>CVA Medios - Clientes</title>
    <BrowserView>
      <HomePage/>
    </BrowserView>
    <MobileView>
      <ConfigProvider locale={esES}>
        <Login/>
      </ConfigProvider>
    </MobileView>
    </div>
  )
}

export default Index