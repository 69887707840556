import React, {useEffect, useRef, memo, useCallback} from 'react'
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table, Badge } from 'antd';
import {DownloadOutlined, FullscreenExitOutlined, FullscreenOutlined} from '@ant-design/icons'
import { DatePicker} from 'antd';
import { Line} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import { isEqual } from "lodash-es";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
const Graphic: React.FC<any> = memo(
  ({ data, onReady, fullScreenF }) => {
    const calcAverageValue = (data, type) => {
      const items = data.filter((d) => d.name === type);
      return items.length ? items.reduce((a, b) => a + b.value, 0)  : '-';
    };
    const [fullScreen, setFullScreen] = React.useState(false);
    useEffect(() => {
      setFullScreen(fullScreenF);
    }, [fullScreenF]);
    const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'name',
      smooth:true,
        xAxis: {
          nice: true,
          // tickCount: 8,
          // 文本标签
          label: {
            autoRotate: true,
            offset: 10,
            style: {
              fill: '#aaa',
              fontSize: 12,
            },
            formatter: (name) => name,
          },
          title: {
            text: 'Fecha',
            style: {
              fontSize: 16,
            },
          },
          // 坐标轴线的配置项 null 表示不展示
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 2,
              stroke: '#aaa',
            },
            length: 5,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [4, 2],
              },
            },
            alternateColor: 'rgba(0,0,0,0.05)',
          },
        },
        tooltip: {
          title: "Valoraciones",
          formatter: (datum) => {
              return { name: datum.name=="+" ? "Positivas " : datum.name=="-" ? "Negativas" : "Neutras", value: datum.value  };
            },
          fields: ['name', 'value'],
        },
        yAxis: {
          label: {
            autoRotate: true,
            style: {
              fill: '#aaa',
              fontSize: 12,
            },
            formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
          title: {
            text: 'Valoración',
            style: {
              fontSize: 16,
            },
          },
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 2,
              stroke: '#aaa',
            },
            length: 5,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [4, 2],
              },
            },
            alternateColor: 'rgba(0,0,0,0.05)',
          },
        },
        meta:{
          value:{
            range:[0,0.9]
          }
          
        },
        legend: {
          position: 'right-top',
          offsetX: 8,
          title: {
            text: 'Total por valoración',
            spacing: 8,
          },
          itemName: {
            formatter: (text, item) => {
              return item.name == "-" ? 'Negativas: ' : item.name == "+" ? 'Positivas: ': 'Neutras: ';
            },
            style: (item, index, fullScreenF) => {
              return {
                fill: item.name == "-" ? 'red' : item.name == "+" ? 'green': 'gray',
              };
            },
          },
          itemValue: {
            formatter: (text, item) => {
              return calcAverageValue(data, item.value);
            },
            style: (item, index) => {
              return {
                fill: item.name == "-" ? 'red' : item.name == "+" ? 'green': 'gray',
              };
            },
          },
        },
        slider: {
          start: 0.0,
          end: 1.0,
        },
        animation: {
          // Configuration of the first animation
          appear: {
            animation: 'wave-in', // Effects of the first animation
            duration: 3000, // Duration of the first animation
          },
        },
        label: {
          layout: [
            {
              type: 'hide-overlap',
            },
          ],
          // 隐藏重叠label
          style: {
            textAlign: 'right',
          },
          formatter: (item) => item.value,
        },
        point: {
          size: 5,
          style: {
            lineWidth: 1,
            fillOpacity: 1,
          },
          shape: (item) => {
            if (item.name === '+') {
              return 'triangle';
            } else if (item.name === '-') {
              return 'triangle-down';

            } else {
              return 'square';
            }
    
            
          },
        },

        annotations: [
          
          {
            type: 'line',
            start: ['min', 'median'],
            end: ['max', 'median'],
            style: {
              stroke: 'Turquoise',
              lineDash: [4, 2],
            },
          },
        ],
        color: ( item ) => {
          return item.name === '-' ? '#F4664A' : item.name === '+' ? '#30BF78' : '#90CAF9';
        },
    };
    return <Line  {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);


const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
function NotesByAssesment(props) {
  
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
      const [data, setData] = React.useState([]);
      useEffect(() => {
      }, [data]);
      useEffect(() => {
        getNotes();
      }, [props.filterDates, props.filterMedia]);
      const [graphic, setGraphic] = React.useState(true);

      const toggleGraphic = () => {
          setGraphic(!graphic);
      }

  const getNotes = () => {        
    client.get("api/notesperassesment?range={\"start\":\""+props.filterDates[0]+"T00:00:00\",\"end\":\""+props.filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\",\"mediaId\":["+props.filterMedia+"]}").then(res=>{
      setData(res["data"]["data"]);

      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVAValoracion-"+props.filterDates[0]+" a " + props.filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVAValoracion-"+props.filterDates[0]+" a " + props.filterDates[1], pdfOptions:{ w:'280', h:'200' ,  x:5, orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

  const handle = useFullScreenHandle();
  const [fullScreen, setFullScreen] = React.useState(false);
  const toggleFullScreen = () => {
    setFullScreen (!fullScreen);
    
  }
  useEffect(()=>{
    if(fullScreen){
      handle.enter();
      
    } else {
      handle.exit();
    }
  }, [fullScreen])
  const reportChange = useCallback((state, hand) => {
    setFullScreen(state);
  }, [handle]);


  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'media',
      render: (text) =>  {return text},
      
      width: '5%',
      defaultSortOrder: 'ascend',
          sorter: (a, b) => moment(a.date) - moment(b.date),
    },
    {
      title: 'Valoración',
      dataIndex: 'name',
      key: 'media',
      render: (text) =>  {let cal = text === "+" ? <Badge color="green" status="processing" text="Positivas"></Badge> : text === "-" ?  <Badge color="red" status="processing" text="Negativas"></Badge> : <Badge color="blue" status="processing" text="Neutras"></Badge>; return cal;},
      
      width: '5%',
      filters: [
        {
          text: 'Positivas',
          value: '+',
        },
        {
            text: 'Negativas',
            value: '-',
        },
        {
            text: 'Neutras',
            value: '=',
        }
      ],
      onFilter: (value, record) => (record.name && record.value>0?record.name.includes(value):false),
    },
    {
      title: 'Cantidad de notas',
      dataIndex: 'value',
      key: 'media',
      render: (text) =>  {return text},
      
      width: '5%',
      sorter: (a, b) => a.value - b.value,
    },
    
  ];


    
  return (
    <>
                  <FullScreen handle={handle} onChange={reportChange}>

                  
                  <h3>Valoración en el periodo de tiempo {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>} </h3>
                  <div ref={referencecomp} style={{height: fullScreen? "95%" : "400px"}}>
                  {
                    graphic &&  <Graphic data={data} fullScreen = {fullScreen}/>

                  }
                   { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ showSizeChanger:"true", position: ["bottomLeft"] }} 
                        size={"middle"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record) => record.date+record.name}
                        scroll={{ y: 350 }}
                        />
                    
                    </>
                  }
                   {((downloadJpg>0) || fullScreen) &&
                    <>
                      <Row>
                        <Col span={18}>
                          Valoración de mis notas generado automáticamente. <br></br>
                      Rango de fechas: {"Desde: " + props.filterDates[0]+ " hasta "+props.filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                      
                    </>
                  }
                   </div>
                   </FullScreen>
                  
    </>
  )
}

export default NotesByAssesment