import React, {useEffect} from 'react';
import {Layout, DatePicker, Input, Select, Space, Button, theme, Pagination, notification } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { SearchOutlined  } from '@ant-design/icons';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import Clip from './Clip';
import Highlighter from 'react-highlight-words';
const pickerFormat = 'YYYY/MM/DD';
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Header, Content, Sider } = Layout;

function MyClipStore(props) {
    const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
    const [filterDates, setFilterDates] = React.useState([moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")]);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [filterMedias, setFilterMedias] = React.useState("");
    const [currentLoading, setCurrentLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0)
  const [mediaData, setMediaData] = React.useState([])
  const [limit, setLimit] = React.useState(50)
    const [currentData, setCurrentData] = React.useState([]);
    const [cols, setCols] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const changeCols = (value) =>{
      setCols(value)
      console.log(cols);
    }
    useEffect(() => {
        getMediaData();
      }, []);
     
function onSelectDate(date, dateString) {
    setFilterDates([dateString[0],dateString[1]]);
  }
  const handleChangeMedia = (values) => {
    
    setFilterMedias(values);
  };
  function fetchData(page, pageSize){
            
    setPage(page);
    setLimit(pageSize);
    searchNotes();
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const getMediaData = () => {        
    client.get("api/media?limit=1000&type_notes=radio").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const claimClip = (datum) => { 
      console.log(datum);
      let data = {
        details: datum.comments,
        start_time: moment(datum.date).format("HH:mm:ss"),
        end_time: moment(datum.date).add(119, 'seconds').format("HH:mm:ss"),
        media: datum.media_id,
        duration: 119,
        date: moment(datum.date).format('YYYY/MM/DD'),
        status: 1,
      }
      console.log(data);
      client.post("api/clipgo", data).then(res=>{
        
        }).catch(err => {
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
      }


  const searchNotes = () => {
    setCurrentLoading(true);
    client.get("api/availableclips?limit="+limit+"&page="+page+"&client_id="+(localStorage.getItem("clientId"))+"&media_id="+(filterMedias?filterMedias:"")+"&search="+filterSearch+"&start_date="+filterDates[0]+" 00:00:00&end_date="+filterDates[1]+" 23:59:59&order=desc").then(res=>{
      setCurrentData(res["data"]["data"]["rows"]);
      setTotal(res["data"]["data"]["total"])
      setCurrentLoading(false);
      }).catch(err => {
        setCurrentLoading(false);
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }



return (
<>
<Space direction="horizontal" size={12} wrap={true}>




Busqueda por fechas: <RangePicker
                        style={{ width: '240px' }}
                        onChange={onSelectDate}
                        defaultValue={[dayjs(moment().format("YYYY/MM/DD"), pickerFormat), dayjs(moment().format("YYYY/MM/DD"), pickerFormat)]}
                        format={pickerFormat} />
                        <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
                      
                      Busqueda por palabra: <Input  style={{ width: '240px' }} placeholder="Buscar" onChange={(e) => {setFilterSearch(e.target.value); }}/>
                      
                      <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
                      Medios:
                      <Select
                        allowClear={true}
                        showSearch
                        mode="single"
                        style={{ width: '240px' }}
                        placeholder="Selecciona los medios que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChangeMedia}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          mediaData.map((data, key) => (
                            <Option value={data.id} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
                      En total se encontraron: {total} resultados.
                      </Space>

              
            <Content
              style={{
                padding: 5,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >          
              
              <div className="clips" style={{display:''}} >{
                currentData.map((data, key) => (
                <Clip 

                  getData = {searchNotes}
                  id = {data.id}
                  own = {0}
                  col = {cols}
                  key = {key}
                  date = {data.date ? data.date.split(".")[0] : data.created_at.split(".")[0]}
                  loading={false}
                  description={data.details}
                  source={"medios/"+ data.media +".png"} 
                  duration = {data.duration}
                  start = {data.start_time? data.start_time : ""}
                  end = {data.end_time? data.end_time:""}
                  searchText={filterSearch}

                  />
              ))}
              </div>
              <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} notas.`} defaultCurrent={1} pageSize={limit} showSizeChanger={true} total={total} onChange={fetchData}  />
            </Content>
</>
)}
export default MyClipStore;