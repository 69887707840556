import React, {useEffect, useState, useRef} from 'react';
import { Table, Badge, Tag, DatePicker, Input, Select, Space, Button, Image, Row, Col } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { SearchOutlined, AudioOutlined, VideoCameraOutlined,CloseOutlined, FilePdfOutlined, BorderlessTableOutlined, GlobalOutlined, FileExcelOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import Highlighter from 'react-highlight-words';
import axios from 'axios';

const pickerFormat = 'YYYY/MM/DD';
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;


function ProTable(props) {
    const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
    const [filterDates, setFilterDates] = React.useState([moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")]);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [filterMedias, setFilterMedias] = React.useState("");
    useEffect(() => {
      getProgramData();
    }, [filterMedias]);
    const [filterPrograms, setFilterPrograms] = React.useState("");
    useEffect(() => {
      getJournalistsData();
    }, [filterPrograms]);
    const [filterJournalists, setFilterJournalists] = React.useState("");
    const [assessment, setAssessment] = React.useState("");
    const [currentData, setCurrentData] = React.useState([]);
    const [filterMedia, setFilterMedia] = React.useState("1,2,3,4,5");
    const [currentLoading, setCurrentLoading] = React.useState(false);
    const [exports, setExports] = React.useState(false);
    const [programData, setProgramData] = React.useState([])
    const [searchText, setSearchText] = useState('');
    const [mediaData, setMediaData] = React.useState(props.mediaData)
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Busqueda`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex.includes("program")?"name":dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex.includes("program")?"name":dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex.includes("program")?"name":dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>

      dataIndex.includes("program")?record.program[dataIndex.split(".")[1]].toString().toLowerCase().includes(value.toLowerCase()):record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      true ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText].concat(JSON.parse(localStorage.getItem("keywords")))}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  function getTypeIcon(text){
    if(text ==="radio"){
        return (<><AudioOutlined />Radio</>);
    }else if(text ==="tv"){
        return (<><VideoCameraOutlined /> Televisión</>);
    } else if(text ==="graphics"){
        return (<><FilePdfOutlined /> Gráfica </>);
    } else if(text ==="social"){
        return (<><BorderlessTableOutlined /> Redes</>);
    } else {
        return (<><GlobalOutlined />Web</>);
    }
  }
  function toggleExport(){
    setExports(!exports);

  }
  function getArrayString(arrayData){

    let result = "["
    arrayData.forEach((element) => result+='"'+element+'",')
    result = result.substring(0, result.length-1) +"]"
    return result;
  }
  function exportExcel(){
    setCurrentLoading(true);
    setExports(!exports);
    client.post('api/reports/exportids', JSON.stringify({ids : selectedRowKeys, keys : localStorage.getItem("keys").split(",")}), { responseType: 'blob' }).then(blob=>{
      const url = window.URL.createObjectURL(blob.data); 
      const a = document.createElement('a');
      a.href = url;
      a.download = "CVA-Reporte-"+moment().format('DD/MM/YY')+" "+selectedRowKeys.length+"Notas.xlsx"  
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setCurrentLoading(false);
    })
    
    setSelectedRowKeys([])
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;


  function currencyFormat(num) {
    if(num == 0){
        return "No disponible"
    }
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

    function getTime(time){
        if(time==null){
            return 0
        }
        var array = time.split(":");
        if(array.length==3){
            return (array[0]*3600)+(array[1]*60)+array[2];
        } else if (array.length==2){
            return (array[0]*60)+array[1];
        }

    }
    const columns = [
      {
        title: 'Valoracion',
        dataIndex: 'keys',
        key: 'assessment',
        width: 100,
        fixed: 'left',
        render: (text) =>  {  let value = 0;
                              let key = text ? text.map((key) => {
                                if(key && props.clientKeys.includes(key.id.toString())){
                                    if(key.symbol=="+"){
                                      console.log("positiva")
                                      value += 1
                                    } else if (key.symbol =="-") {
                                      value += -1;
                                    } }
                                    
                                }) :"";
                              if(value<-2){
                                return <Badge color="red" status="processing" text="Muy negativa"></Badge>
                              } else if (value<0){
                                return <Badge color="red" status="processing" text="Negativa"></Badge>
                              } else if (value >2){
                                return <Badge color="green" status="processing" text="Muy positiva"></Badge>
                              } else if (value >0){
                                return <Badge color="green" status="processing" text="Positiva"></Badge>}
                                else {
                                return <Badge color="blue" status="processing" text="Neutral"></Badge>
                              }}
      },
        {
            title: 'Tipo',
            dataIndex: 'type_note',
            key: 'type',
            render: (type_note) => <div>{type_note?getTypeIcon(type_note):"No asignado"}</div>,
            fixed:'left',
            width: 80,
            filters: [
                {
                  text: 'Radio',
                  value: 'radio',
                },
                {
                    text: 'Televisión',
                    value: 'tv',
                },
                {
                    text: 'Gráfica',
                    value: 'graphics',
                },
                {
                    text: 'Redes Sociales',
                    value: 'social',
                },
                {
                    text: 'Internet',
                    value: 'web',
                }
              ],
              onFilter: (value, record) => (record.type_note?record.type_note.includes(value):false),
        },
        {
          title: 'Medio',
          dataIndex: 'media',
          key: 'media',
          render: (media) =>  <div><Image  width="35px" src={"medios/"+media.id+".png"} alt="logo"></Image>{media.name}
          </div>,
          width: 190,
          filterSearch: true,
          fixed: 'left',
          filters: mediaData ? mediaData.map((media) => {
            return {value:media.id, text:media.name}}):"",
          onFilter: (value, record) => (record.media.id===value),
        },
        {
            title: 'Programa',
            dataIndex: 'program',
            key: 'program',
            filterSearch: true,
            render: (program) =>  <div>{program ? program.name:"N/A"}
            </div>,
            width: 180,
            filters: programData ? programData.map((program) => {
              return {value:program.id, text:program.name}}):"",
            onFilter: (value, record) => (record.program && record.program.id===value),
          },
        {
          title: 'Fecha',
          dataIndex: 'date',
          key: 'date',
          render: (text) =>  {var date = moment(text).format("DD/MM/YYYY");
                              return date},
          width: 95,
        },
        {
          title: 'Hora',
          dataIndex: 'start_time',
          key: 'start_time',
          width: 95,
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.date) - moment(b.date),
        },
        {
            title: 'Duración',
            dataIndex: 'diff_time',
            key: 'diff_time',
            width: 95,
            defaultSortOrder: 'descend',
            sorter: (a, b) => getTime(a.diff_time) - getTime(b.diff_time),
          },
        {
          title: 'Texto',
          dataIndex: 'comments',
          key: 'comments',
          width: 800,
          ellipsis: {
            showTitle: false,
          },
          render: (text) =>
          <Highlighter
            highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[filterSearch].concat(JSON.parse(localStorage.getItem("keywords")))}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />,
          ...getColumnSearchProps('comments'),
        },
        {
            title: 'Periodistas',
            dataIndex: 'journalists',
            key: 'journalists',
            width: 185,
            
            render: (text) =>  (<span>
                                  { text ? text.map((journalist) => {
                                    if(journalist ){
                                        return <Tag key={journalist["full_name"]}>
                                                    {journalist["full_name"]}
                                                </Tag>}
                                    else {
                                      return "";
                                    }}):""}</span>)
                                
          },
          {
            title: 'Temas',
            dataIndex: 'subjects',
            key: 'subjects',
            width: 360,
            
            render: (text) =>  (<span>
                                  { text ? text.map((subject) => {
                                    if(subject){
                                        return <Tag key={subject["name"]}>
                                                    {subject["name"]}
                                                </Tag>}
                                    else {
                                      return "";
                                    }}):""}</span>)
          },
          {
            title: 'Claves',
            dataIndex: 'keys',
            key: 'keys',
            width: 140,
            
            render: (text) =>  (<span>
                                  { text ? text.map((key) => {
                                    if(key && props.clientKeys.includes(key.id.toString())){
                                        return <Tag key={key["name"]}>
                                                    {key["name"]}
                                                </Tag>}
                                    else {
                                      return "";
                                    }}):""}</span>)
          },
          
          {
            title: 'Precio',
            dataIndex: 'diff_time',
            key: 'price',
            width: 120,
            
            render: (value, record) =>  { return record.diff_time && record.program && record.program.price ? currencyFormat(getTime(record.diff_time)*record.program.price) : record.cm && record.media.price ? currencyFormat(getTime(record.diff_time)*record.media.price) : "No disponible"},
          },
        {
          title: 'Enlace principal',
          dataIndex: 'link',
          key: 'link',
          width: 180,
          render: (text) => (text?<a href={text} target='blank'>Acceder al recurso{text.includes("mp3")?"(Audio)":text.includes("mp4")?"(Video)":text.includes("pdf")?"(Pdf)":""}</a>:""),
          ellipsis: {
            showTitle: false,
          },
          filters: [
            {
              text: 'Si tiene enlace',
              value: 'http',
            },
            {
                text: 'No tiene enlace',
                value: null,
            }
          ],
          onFilter: (value, record) => (record.link?record.link.includes(value):record.link==value),
        },
        {
            title: 'Enlace secundario',
            dataIndex: 'linkAlt',
            key: 'linkAlt',
            width: 180,
            render: (text) => (text?<a href={text} target='blank'>Acceder al recurso{text.includes("mp3")?"(Audio)":text.includes("mp4")?"(Video)":text.includes("pdf")?"(Pdf)":""}</a>:""),
            ellipsis: {
              showTitle: false,
            },
            filters: [
                {
                  text: 'Si tiene enlace',
                  value: 'http',
                },
                {
                    text: 'No tiene enlace',
                    value: null,
                }
              ],
              onFilter: (value, record) => (record.linkAlt?record.linkAlt.includes(value):record.linkAlt==value),
          },
          
      ];
    useEffect(() => {
        getMediaData();
        getProgramData();
        getJournalistsData();
      }, []);
      
function onSelectDate(date, dateString) {
    //console.log(dateString);
    setFilterDates([dateString[0],dateString[1]]);
  }
  const handleChangeMedia = (values) => {
    
    setFilterMedias(values);
  };
  
  const handleChangeProgram = (values) => {
    
    setFilterPrograms(values);
  };
  const handleChangeJournalists = (values) => {
    
    setFilterJournalists(values);
  };
  const handleAssesment = (values) => {
    
    setAssessment(values);
  };
  const getMediaData = () => {        
    client.get("api/media?limit=1000").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
  
  const [journalistsData, setJournalistsData] = React.useState([])
  const getProgramData = () => {        
    client.get("api/programs?limit=1000&media_id="+(filterMedias?filterMedias:"")).then(res=>{
      setProgramData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const getJournalistsData = () => {        
      client.get("api/journalists"+(filterPrograms?"/filter-by-program":"")+"?limit=1000&program_id="+(filterPrograms?filterPrograms:"")).then(res=>{
        
        setJournalistsData(res["data"]["data"]["rows"]);
        }).catch(err => {
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
      }

  const searchNotes = () => {
    setCurrentLoading(true);
    client.get("api/reports/notes?limit=500&range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"assessment\":["+(assessment?"\""+assessment+"\"":"")+"],\"client_id\":\""+localStorage.getItem("clientId")+"\",\"media\":["+filterMedia+"],\"journalists\":["+(filterJournalists?filterJournalists:"")+"],\"programs\":["+(filterPrograms?filterPrograms:"")+"],\"mediaid\":["+(filterMedias?filterMedias:"")+"],\"search\":\""+filterSearch+"\" }").then(res=>{
      setCurrentData(res["data"]["data"]["rows"]);
      setCurrentLoading(false);
      }).catch(err => {
        setCurrentLoading(false);
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }

  function getTypeMediaText(id) {
    if(id ==2 ){
      return " Radio "
    } else if(id==4){
      return " Televisión "
    } else if(id==1){
      return " Gráfica "
    } else if(id ==3){
      return " Web "
    } else {
      return " Red social "
    }
  }



return (
<>

                      {!exports && 
                      
                      <>
                      <Space direction="horizontal" size={5} wrap={true}>
                      <Row gutter={[16,0]}>
                        <Col span={8}>
                          Busqueda por fechas: <RangePicker
                        style={{ width: '95%' }}
                        onChange={onSelectDate}
                        defaultValue={[dayjs(moment().format("YYYY/MM/DD"), pickerFormat), dayjs(moment().format("YYYY/MM/DD"), pickerFormat)]}
                        format={pickerFormat} />
                        </Col>
                        <Col span={8}>
                        Busqueda por palabra: <Input  style={{ width: '95%' }} placeholder="Buscar" onChange={(e) => {setFilterSearch(e.target.value); }}/>
                        </Col>
                        <Col span={8}>
                          Medios:
                            <Select
                              allowClear={true}
                              showSearch
                              mode="single"
                              style={{ width: '95%' }}
                              placeholder="Selecciona los medios que quieres consultar"
                              defaultValue={[]}
                              onChange={handleChangeMedia}
                              optionLabelProp="label"
                              optionFilterProp="label"
                            >
                              {
                                mediaData.map((data, key) => (
                                  <Option value={data.id} label={data.name}>
                                    <Space>
                                      {data.name}
                                    </Space>

                                  </Option>
                                ))
                              }
                            </Select>
                        </Col>
                      </Row>
                      <Row Row gutter={[5,0]}>
                        <Col span={8}>
                            Programa: 
                              <Select
                                allowClear={true}
                                showSearch
                                mode="single"
                                style={{ width: '92%' }}
                                placeholder="Selecciona el programa que quieres consultar"
                                defaultValue={[]}
                                onChange={handleChangeProgram}
                                optionLabelProp="label"
                                optionFilterProp="label"
                              >
                                {
                                  programData.map((data, key) => (
                                    <Option value={data.id} label={data.name}>
                                      <Space>
                                        {data.name}
                                      </Space>

                                    </Option>
                                  ))
                                }
                              </Select>
                        </Col>
                        <Col span={8}>
                            Periodista: 
                              <Select
                                allowClear={true}
                                showSearch
                                mode="single"
                                style={{ width: '93%' }}
                                placeholder="Selecciona el periodista que quieres consultar"
                                defaultValue={[]}
                                onChange={handleChangeJournalists}
                                optionLabelProp="label"
                                optionFilterProp="label"
                              >
                                {
                                  journalistsData.map((data, key) => (
                                    <Option value={data.id} label={data.full_name}>
                                      <Space>
                                        {data.full_name}
                                      </Space>

                                    </Option>
                                  ))
                                }
                              </Select> 
                        </Col>
                        <Col span = {8}>
                              Valoracion:
                                <Select
                                  allowClear={true}
                                  showSearch
                                  mode="single"
                                  style={{ width: '93%' }}
                                  placeholder="Selecciona la valoración que quieres buscar"
                                  defaultValue={[]}
                                  onChange={handleAssesment}
                                  optionLabelProp="label"
                                  optionFilterProp="label"
                                >
                                      <Option value="+" label="Positiva">
                                        <Space>
                                          + Positiva
                                        </Space>

                                      </Option>
                                      <Option value="=" label="Neutral">
                                        <Space>
                                          = Neutral
                                        </Space>

                                      </Option>
                                      <Option value="-" label="Negativa">
                                        <Space>
                                          - Negativa
                                        </Space>

                                      </Option>
                                </Select>
                        </Col>

                      </Row>
                      
                      <Row gutter={[150,0]}>
                        <Col span={4}>
                              <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes} disabled={exports}>
                                Buscar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" icon={<FileExcelOutlined />} loading={currentLoading} onClick={toggleExport} disabled={exports}>
                                Generar reporte
                            </Button>
                        </Col>
                      </Row>
                      </Space>
                      
                      
                      
                      
                      
                      </>
                      }
                      
                      { exports &&
                      <>
                        {hasSelected ? `${selectedRowKeys.length} Notas seleccionadas` : ''}
                      <Button type="primary" icon={<CloudDownloadOutlined />} onClick={exportExcel} disabled={!exports}>
                          Descargar reporte
                      </Button>

                      <Button type="primary" danger icon={<CloseOutlined />} onClick={toggleExport} disabled={!exports}>
                          Cancelar
                      </Button>
                      </>
                      }
                      
                      
                      
<Table  virtual bordered={true} rowSelection={!exports?undefined:rowSelection} pagination={{ showSizeChanger:"true", position: ["topRight","bottomLeft"] }} size={"middle"} columns={columns} dataSource={currentData} rowKey={(record) => record._id}
    expandable={{
        expandedRowRender: (record) => <p style={{ margin: 0 }}><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchText, filterSearch].concat(JSON.parse(localStorage.getItem("keywords")))}
        autoEscape={true}
        textToHighlight={record.comments}
      /></p>
    }}
    scroll={{ x: 2900, y: 600 }}/>;
</>
)}
export default ProTable;