import React, {useEffect, useState} from 'react';
import {Layout, DatePicker, Input, Select, Space, Button, theme, Pagination, Divider, Flex, notification, TimePicker } from 'antd';

import moment from 'moment';
import { SearchOutlined, ScissorOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Col, InputNumber, Row, Slider} from 'antd';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
const pickerFormat = 'YYYY/MM/DD';
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Header, Content, Sider } = Layout;


function VideoContent(props) {
  return (
    <>
    
    <ReactPlayer url={props.link} width="100%" controls /> 
    </>
  )
}



function MyClipQuery(props) {
    const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
    const [filterDates, setFilterDates] = React.useState([moment().format("YYYY/MM/DD"),moment().format("YYYY/MM/DD")]);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [date, setDate] = React.useState([moment().format("YYYY/MM/DD")])
    const [time, setTime] = React.useState([moment().format("h:mm:ss")])
    const [end, setEnd] = React.useState([moment().format("h:mm:ss")])
    const [filterMedias, setFilterMedias] = React.useState("");
    const [currentLoading, setCurrentLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0)
  const [mediaData, setMediaData] = React.useState([])
  const [limit, setLimit] = React.useState(50)
    const [currentData, setCurrentData] = React.useState([]);
    const [currentDatatest, setCurrentDatatest] = React.useState([]);
    const [cols, setCols] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [api, contextHolder] = notification.useNotification();

    const changeCols = (value) =>{
      setCols(value)
      console.log(cols);
    }
    useEffect(() => {
        getMediaData();
      }, []);
      
function onSelectDate(date, dateString) {
    setFilterDates([dateString[0],dateString[1]]);
  }
  const handleChangeMedia = (values) => {
    
    setFilterMedias(values);
  };
  function fetchData(page, pageSize){
            
    setPage(page);
    setLimit(pageSize);
    searchNotes();
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const getMediaData = () => {        
    client.get("api/media?limit=1000&type_notes=radio").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }

    const openNotification = () => {
      notification.success({
        type: 'success',
        message: 'Clip creado correctamente!',
        description:
          'Encuentra este y otros recortes hechos por ti en la sección Mis Clips.',
        className: 'custom-class',
        style: {
          width: 600,
        },
      });
    };

    const failNotification = () => {
      notification.warning({
        type: 'warning',
        message: 'Tu clip estará disponible en breve',
        description:
          'Ops! No pudimos generar este clip automáticamente pero puedes encontrarlo en breve en la sección Mis Clips',
        className: 'custom-class',
        style: {
          width: 600,
        },
      });
    };

    useEffect(() => {
      
      const interval = setInterval(()=>{
        console.log("interval")
       if(currentLoading){
        console.log("trying")
        var video = document.createElement("video");
          video.setAttribute("src", currentDatatest);
          video.addEventListener("canplay", function() {
              
              clearInterval(interval);
              setCurrentData(currentDatatest);
              setCurrentLoading(false);
          });
          video.addEventListener("error", function() {
              console.log("video false");
          });
          /*
        fetch(currentDatatest ,
          {    
              //mode: 'no-cors', // no-cors, *cors, same-origin
              headers: {
                'Access-Control-Allow-Origin': '*'
              },
          }).then((response) => {
          console.log(response)
          console.log("si se reproduce")
          setCurrentData("")
          setCurrentData(currentDatatest)
          setCurrentLoading(false)
          clearInterval(interval)
        }).catch((response) => { console.log(response) } )
          */
        } else {
          clearInterval(interval);
        }
      
      }, 5 * 1000 );
    }, [currentDatatest]);



    const failSearch = () => {
      notification.info({
        type: 'info',
        message: 'Estamos buscando tu contenido!',
        description:
          'En breve tendrás el contenido disponible, nuestros servidores están trabajando a toda maquina para ti!',
        className: 'custom-class',
        style: {
          width: 600,
        },
      });
    };

    const warningSearch = () => {
      notification.warning({
        type: 'warning',
        message: 'Este contenido no se encuentra disponible actualmente.',
        description:
          'Vuelve en breve y encuentra tu contenido disponible',
        className: 'custom-class',
        style: {
          width: 600,
        },
      });
    };



    const claimClip = (datum) => { 
      console.log(datum);
      let data = {
        details: datum.comments,
        start_time: moment(datum.date).format("HH:mm:ss"),
        end_time: moment(datum.date).add(119, 'seconds').format("HH:mm:ss"),
        media: datum.media_id,
        duration: 119,
        date: moment(datum.date).format('YYYY/MM/DD'),
        status: 1,
      }
      console.log(data);
      client.post("api/clipgo", data).then(res=>{
        
        }).catch(err => {
          console.log("ERROR OBTENIENDO DATOS<: " + err)
          
        });
      }
  
      const delay = (ms) => {
        return new Promise((resolve) => 
            setTimeout(resolve, ms));
    };

  const searchNotes = () => {
    setCurrentLoading(true);
    client.post("api/clipgo/getResource?&media="+(filterMedias?filterMedias:"")+"&date="+date+"&start_time="+time+"&end_time="+end).then(res=>{
      setCurrentDatatest(res["data"]);
      if(res["data"]=="FILEERROR"){
        warningSearch();
        setCurrentLoading(false);
      }
      
      var start = (parseInt(time.split(":")[0])*3600) + (parseInt(time.split(":")[1])*60 )+ parseInt(time.split(":")[2])
      var ended = parseInt(end.split(":")[0])*3600 + parseInt(end.split(":")[1])*60 + parseInt(end.split(":")[2])
      setTimeTotal(((parseInt(ended)-parseInt(start))))
      
      
      
      
      }).catch(err => {
        setCurrentLoading(false);
        failSearch();
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }

  //SEND CUT CLIP REQUEST
 /* 'media' => 'required', ok
            'start_time' => 'required', ok
            'end_time' => 'required', ok
            'date' => 'required', ok
            'start' => 'required',
            'end' => 'required',
            'source' => 'required',
            'duration' => 'required'*/
    const cutResource = () => {
      setCurrentLoading(true);
      console.log(filterMedias)
      var nameSplited = currentData.split("--")
      var initial_time = nameSplited[1].substring(0,2)+":"+ (parseInt(nameSplited[1].substring(2,4)) + Math.floor(left/60)).toString().padStart(2,"0") + ":"+(parseInt(Math.floor(left%60)).toString().padStart(2,"0"))
      var end_time = nameSplited[1].split(".")[0].substring(0,2)+":"+ (parseInt(nameSplited[1].split(".")[0].substring(2,4)) + Math.floor(right/60)).toString().padStart(2,"0") + ":"+(parseInt(Math.floor(right%60)).toString().padStart(2,"0"))
      var start_cut = "00:"+(Math.floor(left/60).toString().padStart(2,"0"))+":"+(Math.floor(left%60).toString().padStart(2,"0"))
      var end_cut = "00:"+(Math.floor(right/60).toString().padStart(2,"0"))+":"+(Math.floor(right%60).toString().padStart(2,"0"))
      var source = currentData.split("/")[3]
      var duration = right-left
      client.post("api/clipgo/cutResource?&media="+(filterMedias?filterMedias:"")+"&date="+date.replaceAll("/","-")+"&start_time="+initial_time+"&end_time="+end_time+"&start="+start_cut+"&end="+end_cut+"&source="+source+"&duration="+duration+"&clients="+parseInt(localStorage.getItem("clientId"))).then(res=>{
        setCutting(false);
        setCurrentLoading(false);
        openNotification();
        }).catch(err => {
          setCurrentLoading(false);
          failNotification();
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
    }
  


  //SLIDER
    const [timeTotal, setTimeTotal] = useState(1);
    const [timepickerstatus, setTimePickerStatus] = useState("")
    const [left, setLeft] = useState(0);
    const [right, setRight] = useState(timeTotal);
    const formatter = (value) => `${(Math.floor(value/60)).toString().padStart(2,"0") +":"+ Math.floor(value%60).toString().padStart(2,"0")}`;
    const [inputValue, setInputValue] = useState(0);
      const slide = (value) => {
          setLeft(value[0])
          setRight(value[1])
        if (isNaN(value)) {
          setLeft(value[0])
          setRight(value[1])
        } 
      };
      const changeLeft = (value) => {
        setLeft(value);
      };
      const changeRight = (value) => {
        setRight(value);
      };
      const onChange = (value) => {
        
        if (isNaN(value)) {
          setLeft(value[0])
          setRight(value[1])
        } 
      };
    
    //Button cut
    const [cutting, setCutting] = useState(false);
    
    const clickCutting = (value) => {
        setCutting(true);
    };
    const cancelCutting = (value) => {
      setCutting(false);
  };

return (
<>
<LoadingOverlay
      active={currentLoading}
      spinner={<BounceLoader />}
      text='Estamos buscando tu contenido en nuestros servidores, puede tomar algunos minutos...'
    >
<Space direction="horizontal" size={48} wrap={true}>


Medio:
                      <Select
                        allowClear={true}
                        showSearch
                        mode="single"
                        style={{ width: '240px' }}
                        placeholder="Selecciona los medios que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChangeMedia}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          mediaData.map((data, key) => (
                            <Option value={data.id} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
Hora aproximada del clip: <DatePicker
                        onChange={(value, dateString) => {
                            console.log('Selected Time: ', value);
                            setDate(value["$y"]+"/"+(value["$M"]+1).toString().padStart("2", "0")+"/"+value["$D"].toString().padStart("2", "0"))
                            console.log('Formatted Selected Time: ', dateString);
                        }}
                        />
                        <div>
                        
                        <TimePicker.RangePicker
                          status = {timepickerstatus}
                          onChange={(value) => {
                            setTime(value[0]["$H"].toString().padStart("2","0") + ":" + value[0]["$m"].toString().padStart("2","0")+":"+value[0]["$s"].toString().padStart("2","0"))
                            setEnd(value[1]["$H"].toString().padStart("2","0") + ":" + value[1]["$m"].toString().padStart("2","0")+":"+value[1]["$s"].toString().padStart("2","0"))
                            if(value[1] - value[0] > 3600000){
                              setTimePickerStatus("error")
                            } else {
                              setTimePickerStatus("")
                            }
                            console.log(value[1]-value[0])
                        }}
                        ></TimePicker.RangePicker>
                        {(timepickerstatus =="error") && <><br></br>No puede exceder 60 minutos </>}
                        </div>
                      
                      
                      <Button  disabled = {timepickerstatus=="error"} type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
                      <Divider/>
                      </Space>

              
            <Content
              style={{
                padding: 5,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >    <VideoContent link = {currentData}/>
            <Divider></Divider>
            {cutting && <>
              <Row>
          <Col span={3}>
            <InputNumber
              min={0}
              max={timeTotal}
              formatter={formatter}
              style={{
                margin: '2px 2px',
              }}
              step={1}
              value={left}
              onChange={changeLeft}
              addonBefore="Inicio"
            />
          </Col>
          <Col span={18}>
            <Slider
              range={true}
              defaultValue={[0,timeTotal]}
              onChange={slide}
              value= {[left, right]}
              step={1}
              tooltip={{
                formatter,
              }}
              min={0}
              max={timeTotal}
            />
          </Col>
          <Col span={3}>
            <InputNumber
              addonBefore="Fin"
              min={0}
              max={timeTotal}
              formatter={formatter}
              style={{
                margin: '2px 2px',
              }}
              step={1}
              value={right}
              onChange={changeRight}
            />
          </Col>
        </Row>
             <Divider/></>}
            <Flex wrap gap="small">
              
              { cutting && <>
                  
                  <Button type="primary" success onClick={cutResource}> <SaveOutlined /><ScissorOutlined /> Guardar recorte</Button>
                  <Button type="primary" onClick={cancelCutting} danger> <CloseCircleOutlined /> Cancelar</Button>
              </>}
              {
              !cutting && <> 
                <Button type="primary" onClick={clickCutting} > <ScissorOutlined /> Recortar Clip</Button>
                <Button type="primary"  success> <SaveOutlined /> Guardar clip</Button>
              </>
              }
              
              </Flex>
            </Content>  
    </LoadingOverlay>
</>
)}
export default MyClipQuery;