import React from 'react'
import {DollarOutlined, CalendarOutlined, ClockCircleOutlined} from '@ant-design/icons';
function Tone(props) {
    const date = props.date.split("T")
    //console.log(props.cm2)
    function currencyFormat(num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
   }
  return (
    <React.Fragment>
        <DollarOutlined/>  { (props.price === (null) ? " Sin precio asignado" : props.cm2 === "0" ? props.duration === "0" ? props.price + " / sec o cm2" : currencyFormat(props.price*props.duration) : currencyFormat(props.price * props.cm2)) }
        <br></br> 
        <CalendarOutlined />  { date[0] } <ClockCircleOutlined /> { date[1] }
    </React.Fragment>
  )
}

export default Tone