import React, {useEffect} from 'react';
import "./mainMenu.css";
import {CalendarOutlined, InteractionOutlined, FolderViewOutlined, UserOutlined, MailOutlined, PlayCircleOutlined, FontColorsOutlined, FilePdfOutlined, DeploymentUnitOutlined, FunctionOutlined, SoundOutlined, VideoCameraOutlined, WhatsAppOutlined, SearchOutlined, BorderlessTableOutlined, LineChartOutlined,AppstoreOutlined, GlobalOutlined  } from '@ant-design/icons';
import { DatePicker, Space , Breadcrumb, Button, Divider, Layout, Menu, theme, Input, Pagination, Select } from 'antd';
import Statistics from '../Statistics/Statistics.js'
import  LogOut  from '../Login/LogOut';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import Note from "../Cards/Note";
import Transcriptor from "../IaComponents/Transcriptor";
import IaWordCloud from '../IaComponents/Iawordcloud.js';
import LiveContent from "./LiveContent.js";
import dayjs from 'dayjs';
import Tone from '../Cards/Tone.js'
import MyClips from '../Clipgo/MyClips.js';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FloatButton, Tag } from 'antd';
import ProTable from './ProTable.js';
const { Header, Content, Sider } = Layout;

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;




const items1: MenuProps['items'] = [
  {
    label: 'Todos',
    key: '[1,2,3,4,5]',
    icon: <AppstoreOutlined />,
  },
    {
      label: 'Gráfica',
      key: '[1]',
      icon: <FilePdfOutlined />,
    },
    {
      label: 'TV',
      key: '[4]',
      icon: <VideoCameraOutlined />,
    },
    {
      label: 'Radio',
      key: '[2]',
      icon: <SoundOutlined />,
    },
    
    {
      label: 'Internet',
      key: '[3]',
      icon: <GlobalOutlined />,
    },
    
    {
      label: 'Redes Sociales',
      key: '[5]',
      icon: <BorderlessTableOutlined />,
    },];
  
  
  const MainMenu = () => {
      const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")



    const [currentData, setCurrentData] = React.useState([]);
    const [filterDates, setFilterDates] = React.useState(["",""]);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [cols, setCols] = React.useState(0);
    useEffect(() => {
      if(searcher){
        searchNotes();
      }
      
      
    }, [filterSearch]);

    useEffect(() => {
      setTimeout(() => {
        items2[4].disabled=localStorage.getItem("iabasic")==="0";
      }, "1000");
      
      items2[4].disabled=localStorage.getItem("iabasic")==="0";
    }, [])
    const [filterMedia, setFilterMedia] = React.useState("[1,2,3,4,5]")
    useEffect(() => {
      if(!searcher){
        getNotes();
      } else {
        searchNotes();
      }
      
    }, [filterMedia]);
    const [filterMedias, setFilterMedias] = React.useState("")
    const [searcher, setSearcher] = React.useState(false)
    useEffect(() => {
      setPage(1);
      setTotal(0);
      if(!searcher){
        getNotes();
      } else {
        searchNotes();
      }
      
    }, [searcher]);

    
    const [statistics, setStatistics] = React.useState(false)
    const [ia, setIa] = React.useState(false)
    const [iawordcloud, setIaWordcloud] = React.useState(false)
    const [live, setLive] = React.useState(true)
    const [livecontent, setLiveContent] = React.useState(false)
    const [pro, setPro] = React.useState(false)
    const [myclips, setMyClips] = React.useState(false)
    const [currentLoading, setCurrentLoading] = React.useState(false)
    const [mediaData, setMediaData] = React.useState([])
    const [limit, setLimit] = React.useState(50)
    const [page, setPage] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    useEffect(() => {
      
      if(!searcher){
        getNotes();
      } else {
        searchNotes();
      }
      
    }, [limit, page]);
    const [collapsed, setCollapsed] = React.useState(false);
    const getNotes = () => {
          setCurrentLoading(true);
          client.get("api/reports/notes?page="+page+"&limit="+limit+"&range={\"start\":\"2021-09-26T00:00:00\",\"end\":\"2025-09-26T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\",\"media\":"+filterMedia+"}").then(res=>{
            items2[4].disabled=localStorage.getItem("iabasic")==="0";
            setTotal(res["data"]["data"]["total"])
            setCurrentData(res["data"]["data"]["rows"]);
            setCurrentLoading(false);
            }).catch(err => {
              setCurrentLoading(false);
              console.log("ERROR OBTENIENDO DATOS<: " + err)
            });
          }
          function fetchData(page, pageSize){
            
            setPage(page);
            setLimit(pageSize);
            if(!searcher){
              getNotes();
            } else {
              searchNotes();
            }
          }

          function onSelectDate(date, dateString) {
            //console.log(dateString);
            setFilterDates([dateString[0],dateString[1]]);
          }
          const handleChange = (values) => {
            //console.log({values});
            setFilterMedia(values);
          
          };

          const changeCols = (value) =>{
            setCols(value)
          }

          const handleChangeMedia = (values) => {
            //console.log({values});
            setFilterMedias(values);
          
          };
          const items2: MenuProps2['items']= [
            {
              label: 'Monitoreo humano',
              key: "live",
              icon: <InteractionOutlined />,
            },
            {
              label: 'Clip Go',
              key: "myclips",
              icon: <FolderViewOutlined />
            },
            {
              label: 'Consultar por fecha',
              key: "search",
              icon: <CalendarOutlined />,
        
            },
            {
              label: 'Reportes',
              key: "pro",
              icon: <FunctionOutlined />,
            },
            {
              label: 'Monitoreo IA',
              icon: <DeploymentUnitOutlined />,
              disabled: localStorage.getItem("iabasic")==="0",
              children: [
                {
                  label: 'Busqueda IA',
                  key: 'iaconsult',
                  icon: <DeploymentUnitOutlined />,
                },
                {
                  label: 'Nube de palabras IA',
                  key: 'iawordcloud',
                  icon: <DeploymentUnitOutlined />,
                },   
        
              ]
        
        
        
            },
             
            {
              label: 'Estadísticas',
              key: 'statistics',
              icon: <LineChartOutlined />,
            },
            {
              label: 'Stream en vivo',
              key: "livecontent",
              icon: <PlayCircleOutlined />,
            },
            {
              label: 'Gráfica',
              key: 'graphics',
              icon: <FilePdfOutlined />,
              disabled: true,
            },
            {
              label: 'Tapas de diarios',
              key: 'tapas',
              icon: <FilePdfOutlined />,
              disabled: true,
            },
            
          ];
          
    const getMediaData = () => {        
          client.get("api/media?limit=1000").then(res=>{
            setMediaData(res["data"]["data"]["rows"]);
            }).catch(err => {
              console.log("ERROR OBTENIENDO DATOS<: " + err)
            });
          }
    const searchNotes = () => {
      setCurrentLoading(true);
      client.get("api/reports/notes?page="+page+"&limit="+limit+"&range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\",\"media\":["+filterMedia+"],\"mediaid\":["+filterMedias+"],\"search\":\""+filterSearch+"\" }").then(res=>{
            setCurrentLoading(false);
            setTotal(res["data"]["data"]["total"])
        setCurrentData(res["data"]["data"]["rows"]);
        }).catch(err => {
          setCurrentLoading(false);
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
      }
      function changeSize(current, pageSize) {
        setLimit(limit);
      }
      function getTime(time){
        if(time==null){
            return 0
        }
        var array = time.split(":");
        if(array.length==3){
            return (array[0]*3600)+(array[1]*60)+array[2];
        } else if (array.length==2){
            return (array[0]*60)+array[1];
        }

    }
    
      const onClick: MenuProps['onClick'] = e => {
        //console.log('click ', e);
        setFilterMedia(e.key);
      };

      const lateralClick: MenuProps2['onClick'] = m => {
        //console.log('click ', m);
        if(m.key==="live"){
          setSearcher(false)
          setStatistics(false)
          setPro(false)
          setIa(false)
          setLive(true)
          setLiveContent(false)
          setMyClips(false)
          setIaWordcloud(false)
          setFilterMedia("[1,2,3,4,5]")
          getNotes()
        } else if (m.key==="myclips") {
          setSearcher(false)
          setStatistics(false)
          setPro(false)
          setIa(false)
          setLive(false)
          setMyClips(true)
          setLiveContent(false)
          setIaWordcloud(false)
          setCurrentData([])

        }       
        else if(m.key==="search") {
          setSearcher(true)
          setStatistics(false)
          setPro(false)
          setIa(false)
          setLive(false)
          setLiveContent(false)
          setMyClips(false)
          setIaWordcloud(false)
          setCurrentData([])
          getMediaData()
        } else if(m.key==="statistics"){
          setSearcher(false)
          setStatistics(true)
          setPro(false)
          setIa(false)
          setLive(false)
          setLiveContent(false)
          setIaWordcloud(false)
          setMyClips(false)
          setCurrentData([])
          setFilterMedia("[1,2,3,4,5]")
        } else if(m.key==="iaconsult"){
          setSearcher(false)
          setStatistics(false)
          setPro(false)
          setIa(true)
          setLive(false)
          setLiveContent(false)
          setIaWordcloud(false)
          setMyClips(false)
          setCurrentData([])
          setFilterMedia("[]")
        } else if(m.key==="pro"){
          setSearcher(false)
          setStatistics(false)
          setIa(false)
          setPro(true)
          setLive(false)
          setLiveContent(false)
          setIaWordcloud(false)
          setMyClips(false)
          setCurrentData([])
          setFilterMedia("[1,2,3,4,5]")
        } else if(m.key==="livecontent"){
          setSearcher(false)
          setStatistics(false)
          setIa(false)
          setPro(false)
          setLive(false)
          setLiveContent(true)
          setIaWordcloud(false)
          setMyClips(false)
          setCurrentData([])
          setFilterMedia("[1,2,3,4,5]")
        } else if(m.key==="iawordcloud"){
          setSearcher(false)
          setStatistics(false)
          setIa(false)
          setPro(false)
          setLive(false)
          setLiveContent(false)
          setIaWordcloud(true)
          setMyClips(false)
          setCurrentData([])
          setFilterMedia("[1,2,3,4,5]")
        }
        
      };
        
      
    const {
      token: { colorBgContainer },
    } = theme.useToken();


    return (
      <Layout>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        > 
          <div className="demo-logo" />
                            <img src={"logocva.png"} style={ {width:"auto"} } alt="No disponible"/>
          
          {live && <>
          
            <Menu theme="dark" fontSize={40} mode="horizontal" defaultSelectedKeys={['0']} items={items1} onClick={onClick}
            >
          </Menu>

          
          
          
          <Button onClick={getNotes} loading={currentLoading}> Refrescar notas </Button>
          </>
        }
          <div style={{position: "absolute",
                        right: "130px",
                        padding: "10px"}} >
            <p style={{ color:'white' }}>Hola, {localStorage.getItem("name")}.</p>
          </div>
          <div style={{position: "absolute",
                        right: "15px",
                        padding: "10px"}}>          
            <LogOut />
          </div>
          
          
        </Header>
        <Layout style={{
              padding: '0px 0px 24px',
            }}>
          <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
            width={250}
            style={{
              
              padding: '5px 0px 0px',
              background: colorBgContainer,
            }}
          >
            
            <Menu
              mode="inline"
              defaultSelectedKeys={['live']}
              defaultOpenKeys={['sub1']}
              style={{
                borderRight: 0,
              }}
              items={items2}
              onClick={lateralClick}
            />
            { !collapsed &&  <>
            <Layout style={{
              padding: '35px 10px 24px',
              background: colorBgContainer,
            }}>
              
            
            <div>
              <Divider></Divider>
              <span><UserOutlined/> {localStorage.getItem("name")}</span><br></br>
              
              <span><MailOutlined /> {localStorage.getItem("email")}</span>
              <Divider></Divider>
            </div>
            <Layout style={{
              padding: '15px 10px 24px',
            }}>Mis claves asignadas: {localStorage.getItem("keysNames").split(",").map((value)=>{
              return <Tone  name = {value} color = {value.includes("=") ? "=" : value.includes("+") ? "+" : "-"}/>
          })} <br></br>
          </Layout>
            
          <Layout style={{
              padding: '15px 10px 24px',
            }}>
              <Divider></Divider>
            Palabras clave: {JSON.parse(localStorage.getItem("keywords")).map((value) => {
              return <Tag icon={<FontColorsOutlined   style={{ fontSize: '180%'}}/>} >{value}</Tag>
            })} 
            </Layout>
            </Layout>
            </> 
            }
          </Sider>
          <Layout
          
            style={{
              padding: '0px 10px 24px',
            }}
          >
            
            
              

              {searcher &&
              <Breadcrumb
              style={{
                margin: '5px 24px 0',
              }}
            > 
              <Breadcrumb.Item><Space direction="horizontal" size={12} wrap={true} disabled={searcher}>
                      Busqueda por fechas: <RangePicker
                        onChange={onSelectDate}
                        defaultValue={[dayjs('2023/09/01', dateFormat), dayjs('2023/09/30', dateFormat)]}
                        format={dateFormat} />
                      Busqueda por palabra: <Input placeholder="Buscar" onChange={(e) => {setFilterSearch(e.target.value)}}/>
                      Tipo de medio: 
                      <Select
                        mode="multiple"
                        style={{ width: '360px' }}
                        placeholder="Selecciona los tipos de medios que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChange}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        <Option value="1" label="Grafica">
                          <Space>
                            1 - Grafica
                          </Space>
                        </Option>
                        <Option value="2" label="Radio">
                          <Space>
                            2 - Radio
                          </Space>
                        </Option>
                        <Option value="5" label="Redes Sociales">
                          <Space>
                            3 - Redes Sociales
                          </Space>
                        </Option>
                        <Option value="4" label="Television">
                          <Space>
                            4 - Television
                          </Space>
                        </Option>
                        <Option value="3" label="Internet">
                          <Space>
                            5 - Internet
                          </Space>
                        </Option>
                      </Select>
                      Medios:
                      <Select
                        mode="multiple"
                        style={{ width: '360px' }}
                        placeholder="Selecciona los medios que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChangeMedia}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          mediaData.map(data => (
                            <Option value={data.id} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      <Button type="primary" icon={<SearchOutlined />} onClick={searchNotes} loading={currentLoading}>
                          Buscar
                      </Button>
              </Space> </Breadcrumb.Item>
                


              </Breadcrumb>

               }
              
            {!statistics && !ia && !pro && !livecontent && !myclips && !iawordcloud &&
            <>
            <p>Cantidad de notas por linea <Select
                        mode="single"
                        style={{ width: '25%' }}
                        placeholder="Cantidad de notas por linea"
                        defaultValue={[]}
                        onChange={changeCols}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        <Option value="0" label="Automático">
                          <Space>
                            Automático
                          </Space>
                        </Option>
                        <Option value="1" label="1">
                          <Space>
                            1 Nota
                          </Space>
                        </Option>
                        <Option value="2" label="2">
                          <Space>
                            2 Notas
                          </Space>
                        </Option>
                        <Option value="3" label="3">
                          <Space>
                            3 Notas
                          </Space>
                        </Option>
                        <Option value="4" label="4">
                          <Space>
                            4 Notas
                          </Space>
                        </Option>
                        <Option value="5" label="5">
                          <Space>
                            5 Notas
                          </Space>
                        </Option>
                      </Select></p>
              
            <Content
              style={{
                padding: 5,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >          
              
              <div className="notes" style={{display:'flex'}} >{
                currentData.map((data, key) => (
                <Note 
                  id = {data._id}
                  media_id = {data.media.id}
                  seconds={data.full_seconds? data.full_seconds:0}
                  col = {cols}
                  key = {key}
                  country = {data.media.country ? data.media.country : ""}
                  program={"Programa: "+ (data.program ? data.program.name : "No hay descripción del programa.")}
                  date = {data.date ? data.date.split(".")[0] : data.created_at.split(".")[0]}
                  loading={false}
                  title={data.type_note === "social" ? data.web_username : (data.type_note === "radio" || data.type_note === "tv") ? data.media.name : data.title}
                  journalist={data.journalists ? (data.journalists.length>0 ? data.journalists[0].name+" "+data.journalists[0].surname : "Sin periodista") : data.web_username? data.web_username: "Sin periodista."}
                  keys = {data.keys}
                  description={data.comments}
                  source={"medios/"+ data.media.id +".png"} 
                  link = {data.link ? data.link:""}
                  estimation = {data.estimation? data.estimation : 0}
                  price = {data.program ? data.program.price ? data.program.price : data.media.price : data.media.price? data.media.price:null}
                  cm2 = {data.cm ? data.cm : "0"}
                  duration = {data.diff_time ? getTime(data.diff_time) : "0"}
                  type = {data.type_note ? data.type_note : ""}
                  start = {data.start_time? data.start_time : ""}
                  end = {data.end_time? data.end_time:""}
                  web_username = {data.web_username}
                  linkAlt = {data.linkAlt ? data.linkAlt : ""}
                  linkAlt2 = {data.linkAlt2 ? data.linkAlt2 : ""}
                  linkAlt3 = {data.linkAlt3 ? data.linkAlt3 : ""}
                  diff_time = {data.diff_time}
                  clientKeys = {localStorage.getItem("keys")}
                  searchText={filterSearch}

                  />
              ))}
              </div>
              <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} notas.`} defaultCurrent={1} pageSize={limit} showSizeChanger={true} total={total} onChange={fetchData}  />
            </Content>
            </>
            }{statistics &&
              <div key="Statistics">
                <Statistics/>
              </div>
            }
            {ia && 
            <Layout
            style={{
              padding: '5px 10px 24px',
            }}
            > 
              
            <div key="Transcriptor">
              <Transcriptor mediaData = {mediaData}></Transcriptor>
            </div>
            </Layout>}
            {
              pro &&
              <Layout
            style={{
              padding: '5px 10px 24px',
            }}
            > 
              <div key="ProTable">
                <ProTable clientKeys = {localStorage.getItem("keys").split(",")} mediaData = {mediaData} filterDates = {filterDates} filterMedias = {filterMedias} filterSearch = {filterSearch}></ProTable>
              </div>
              </Layout>
            }

            {
              livecontent &&
              <Layout
            style={{
              padding: '5px 10px 24px',
            }}
            > 
              <div key="ProTable">
                <LiveContent></LiveContent>
              </div>
              </Layout>
            }
            {
              iawordcloud &&
              <Layout
            style={{
              padding: '5px 10px 24px',
            }}
            > 
              <div key="ProTable">
                <IaWordCloud></IaWordCloud>
              </div>
              </Layout>
            }
            {
              myclips &&
              <Layout
            style={{
              padding: '5px 10px 24px',
            }}
            > 
              <div key="ProTable">
                <MyClips></MyClips>
              </div>
              </Layout>
            }
            <FloatButton.BackTop />
          </Layout>
          
        </Layout>
        
      </Layout>
    )
  }
  
  export default MainMenu;