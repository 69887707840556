import React from 'react';
import { Tag } from 'antd';
import {SmileTwoTone, FrownTwoTone, MehTwoTone } from '@ant-design/icons';
function Tone(props) {
  return (
    <React.Fragment>
        {props.color.includes("-") ? <Tag icon={<FrownTwoTone twoToneColor={"#ff4d4f"} style={{ fontSize: '180%'}}/>} color="error">{props.name}</Tag> : props.color.includes("+") ? <Tag icon={<SmileTwoTone twoToneColor={"#25cf13"} style={{ fontSize: '180%'}}/>} color="green">{props.name}</Tag> : <Tag icon={<MehTwoTone style={{ fontSize: '180%'}}/> } color="processing">{props.name}</Tag>}<br></br>
    </React.Fragment>
  )
}

export default Tone