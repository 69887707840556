
import React, {useEffect, useRef, memo, useCallback} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons'
import { DatePicker} from 'antd';
import { Column} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import dayjs from 'dayjs';
import { isEqual } from "lodash-es";
const Graphic: React.FC<any> = memo(
  ({ data, onReady }) => {
    const config = {
      data,
      xField: 'name',
      yField: 'value',
      slider: {
        start: 0.0,
        end: 1.0,
      },
      yAxis:{
        title: {
          text: 'Cantidad de notas',
          style: {
            fontSize: 16,
          },
        },
      },
      label: {
        content: (originData) => {
          const val = parseInt(originData.value);
          return val
        },
        offset: 10,
      },
      legend: true,
      xAxis: {
        title: {
          text: 'Temas de las notas',
          style: {
            fontSize: 16,
          },
        },
        label: {
          layout: [
            {
              type: 'hide-overlap',
            },
          ],
          autoHide: false,
          autoRotate: true,
        },
      },
    };
    return <Column {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
function NotesBySubjectId(props) {
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
  const [data, setData] = React.useState([]);
  useEffect(() => {
  }, [data]);
  const [filterDates, setFilterDates] = React.useState(["2020/01/01","2025/12/31"]);
  useEffect(() => {
    getNotes();
  }, [filterDates]);


  const getNotes = () => {        
    client.get("api/notespersubject?range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\"}").then(res=>{
      setData(res["data"]["data"]);
      props.medias(res["data"]["data"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVATemasId-"+filterDates[0]+" a " + filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVATemasId-"+filterDates[0]+" a " + filterDates[1], pdfOptions:{ w:'280', h:'200' ,  orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

    function onSelectDate(date, dateString) {
      console.log("changing Dates")
      setFilterDates([dateString[0],dateString[1]]);
    }


    const handle = useFullScreenHandle();
    const [fullScreen, setFullScreen] = React.useState(false);
    const toggleFullScreen = () => {
      setFullScreen (!fullScreen);
      
    }
    useEffect(()=>{
      if(fullScreen){
        handle.enter();
        
      } else {
        handle.exit();
      }
    }, [fullScreen])
    const reportChange = useCallback((state, hand) => {
      setFullScreen(state);
    }, [handle]);
  

    const [graphic, setGraphic] = React.useState(true);

      const toggleGraphic = () => {
          setGraphic(!graphic);
      }
    
      const columns = [
        
        {
          title: 'Tema',
          dataIndex: 'name',
          key: 'media',
          render: (text) =>  {return <>{ text}</>;},
          sorter: (a, b) => (!a.name || !b.name) ? false:a.name.localeCompare(b.name),
          width: '5%',
        },
        {
          title: 'Cantidad de notas con este tema',
          dataIndex: 'value',
          key: 'value',
          render: (text) =>  {return text},
          
          width: '5%',
          sorter: (a, b) => a.value - b.value,
        },
        
      ];
  
  return (
    <>        <FullScreen handle={handle} onChange={reportChange}>
                  <h2>Temas relacionados con mis notas {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>} </h2> <RangePicker
                          onChange={onSelectDate}
                          defaultValue={[dayjs('2020/01/01 ', dateFormat), dayjs('2025/12/31', dateFormat)]}
                          format={dateFormat} />
                  
                  <div ref={referencecomp}  style={{height: fullScreen? "90%" : "650px"}}>
                  
                  {
                    graphic &&  <Graphic data={data} />

                  }
                  { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ position: ["bottomLeft"] }} 
                        size={"middle"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record, index) => record.name+record.value+record.type+index}
                        scroll={{ y: 540 }}
                        />
                    
                    </>
                  }
                  {((downloadJpg>0) || fullScreen) && 
                   <>

                      <Row>
                        <Col span={16}>
                          Cantidad de notas por tema generada automáticamente. <br></br>
                     Rango de fechas: {"Desde: " + filterDates[0]+ " hasta "+filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                     
                   </>
                 }
                  </div>
                  </FullScreen>
    </>
  )
}

export default NotesBySubjectId