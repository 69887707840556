import React, {useEffect} from 'react';
import { Table, DatePicker, Input, Select, Space, Button, Image, notification } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { SearchOutlined  } from '@ant-design/icons';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
const pickerFormat = 'YYYY/MM/DD HH:mm';
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;


function Transcriptor(props) {
    const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
    const [filterDates, setFilterDates] = React.useState([moment().format("YYYY/MM/DD HH:00"),moment().format("YYYY/MM/DD HH:59")]);
    const [filterSearch, setFilterSearch] = React.useState("XXXXXXXNOBUSCABLEXXXXXXXX");
    const [filterMedias, setFilterMedias] = React.useState("");
    const [currentLoading, setCurrentLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0)
  const [mediaData, setMediaData] = React.useState([]) 
    const [currentData, setCurrentData] = React.useState([]);
    const [api, contextHolder] = notification.useNotification();
    const clipNotification = () => {
      console.log("NOTIFICACION");
      notification.success({
        message: '¡Estamos creando tu clip!',
        description:
          'En breve podrás encontrar ese clip en la sección Mis Clips.',
        type: 'success'
      });
    };


    const columns = [
        {
          title: 'Medio',
          dataIndex: 'media_id',
          key: 'media',
          render: (text) =>  <div><Image  width="50px" src={"medios/"+text+".png"} alt="logo"></Image>{mediaData.find(x => x.id === text) ? mediaData.find(x => x.id === text).name :""}
          </div>,
          width: '12%',
        },
        {
          title: 'Fecha',
          dataIndex: 'date',
          key: 'date',
          render: (text) =>  {var date = moment(text).format("DD/MM/YYYY");
                              return date},
          width: '5%',
        },
        {
          title: 'Hora',
          dataIndex: 'date',
          key: 'time',
          width: '8%',
          render: (text) =>  {var date = moment(text);
              return (date.format("HH:mm:ss")+" a " +date.add(119, 'seconds').format("HH:mm:ss"))},
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.date - b.date,
        },
        {
          title: 'Texto',
          dataIndex: 'comments',
          key: 'comments',
          width: '55%',
          ellipsis: {
            showTitle: false,
          },
          render: (text) =>
          <Highlighter
            highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[filterSearch].concat(JSON.parse(localStorage.getItem("keywords")))}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
          ,
        },
        {
          title: 'Clip',
          dataIndex: 'path',
          key: 'path',
          width: '18%',
          render: (value, record) => { return (<> <Button onClick={()=>claimClip(record)}>Solicitar clip</Button></>)},
          ellipsis: {
            showTitle: false,
          },
        },
      ];
    useEffect(() => {
        getMediaData();
      }, []);
      
function onSelectDate(date, dateString) {
    setFilterDates([dateString[0],dateString[1]]);
  }
  const handleChangeMedia = (values) => {
    
    setFilterMedias(values);
  };
  const getMediaData = () => {        
    client.get("api/media?limit=1000&type_notes=radio").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    
    const claimClip = (datum) => { 
      
      let data = {
        details: datum.comments,
        start_time: moment(datum.date).format("HH:mm:ss"),
        end_time: moment(datum.date).add(119, 'seconds').format("HH:mm:ss"),
        media: datum.media_id,
        duration: 119,
        date: moment(datum.date).format('YYYY/MM/DD'),
        status: 1,
        clients: parseInt(localStorage.getItem("clientId")),
      }
      client.post("api/clipgo", data).then(res=>{
        clipNotification();
        }).catch(err => {
          console.log("ERROR OBTENIENDO DATOS<: " + err)
        });
      }


  const searchNotes = () => {
    setCurrentLoading(true);
    client.get("api/nnbridge?limit=2000&media_id="+(filterMedias?filterMedias:"")+"&search="+filterSearch+"&start="+filterDates[0]+":00&end="+filterDates[1]+":59&order=desc").then(res=>{
      setCurrentData(res["data"]["data"]["rows"]);
      setTotal(res["data"]["data"]["total"])
      setCurrentLoading(false);
      }).catch(err => {
        setCurrentLoading(false);
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }




return (
<>
<Space direction="horizontal" size={12} wrap={true}>
Selecciona un medio:
                      <Select
                        allowClear={true}
                        showSearch
                        mode="single"
                        style={{ width: '240px' }}
                        placeholder="Selecciona los medios que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChangeMedia}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          mediaData.map((data, key) => (
                            <Option value={data.id} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
Busqueda por fechas: <RangePicker
                        showTime={true}
                        showHour={true}
                        style={{ width: '320px' }}
                        onChange={onSelectDate}
                        defaultValue={[dayjs(moment().format("YYYY/MM/DD HH:00"), pickerFormat), dayjs(moment().format("YYYY/MM/DD HH:59"), pickerFormat)]}
                        format={pickerFormat} />
                        <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>

                      
                      {localStorage.getItem("iafull")>0 && 
                      <>
                      Busqueda por palabra: <Input  style={{ width: '240px' }} placeholder="Buscar" onChange={(e) => {setFilterSearch(e.target.value); }}/>
                      
                      
                      o
                      </>}
                       {localStorage.getItem("iafull")==0?"Buscar mis palabras:":"buscar mis palabras:"} 
                      
                      <Select
                        allowClear={true}
                        showSearch
                        mode="single"
                        style={{ width: '240px' }}
                        placeholder="Selecciona la palabra que quieres buscar"
                        defaultValue={[]}
                        onChange={setFilterSearch}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          JSON.parse(localStorage.getItem("keywords")).map((data, key) => (
                            <Option value={data} label={data}>
                              <Space>
                                {data}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      <Button type="primary" loading={currentLoading} icon={<SearchOutlined />} onClick={searchNotes}>
                          Buscar
                      </Button>
                      
                      
                      En total se encontraron: {total} resultados.
                      </Space>
<Table bordered={true} pagination={{ showSizeChanger: true, position: ["topRight","bottomLeft"] }} size={"middle"} columns={columns} dataSource={currentData} rowKey={(record) => record._id}
    expandable={{
        expandedRowRender: (record) => <p style={{ margin: 0 }}><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[filterSearch].concat(JSON.parse(localStorage.getItem("keywords")))}
        autoEscape={true}
        textToHighlight={record.comments}
      /></p>
    }}/>;
</>
)}
export default Transcriptor;