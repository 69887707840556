import React, {useEffect} from 'react'
import { Col, Row, Divider, Select, Space, Slider} from 'antd';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import LivePlayer from './LivePlayer';
import { DatePicker} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
const colCounts = {};
[1, 2, 3, 4].forEach((value, i) => {
    colCounts[i] = value;
  });
  
  const rowCounts = {};
  [1, 2, 3, 4, 5].forEach((value, i) => {
      rowCounts[i] = value;
    });
function LiveContent() {
      const jar = new CookieJar();
      
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
      const [data, setData] = React.useState([]);
      const [colCountKey, setColCountKey] = React.useState(1);
      const [rowCountKey, setRowCountKey] = React.useState(1);
      const cols = [];
      const colCount = colCounts[colCountKey];
      const rows = [];
      const rowCount = rowCounts[rowCountKey];
      let colCode = '';
      for (let i = 0; i < colCount; i++) {
        cols.push(
          <Col  key={i.toString()} span={24 / colCount}>
            <LivePlayer livestreams={data} />
          </Col>,
        );
        colCode += `  <Col span={${24 / colCount}} />\n`;
      }
      
      
      let rowCode = '';
      for (let i = 0; i < rowCount; i++) {
        rows.push(
          <Row gutter={[2,5]} key={i.toString()} span={24 / rowCount}>
            {cols}
          </Row>,
        );
       rowCode += `  <Row span={${24 / rowCount}} />\n`;
      }


      useEffect(() => {
        getMedias();
      }, []);
      useEffect(() => {
        console.log(data);
      }, [data]);
      
      const getMedias = () => {        
        client.get("api/live").then(res=>{
          setData(res["data"]["data"]["rows"]);
          }).catch(err => {
            console.log("ERROR OBTENIENDO DATOS<: " + err)
          });
        }
      const changeCols = (value) => {
        console.log(value);
      }
      
  return (
    <>  <Row>
          <Col span={10}>
          <span>Columnas:</span>
                <div
                    style={{
                    width: '50%',
                    marginBottom: 48,
                    }}
                >
                    <Slider
                    min={0}
                    max={Object.keys(colCounts).length - 1}
                    value={colCountKey}
                    onChange={setColCountKey}
                    marks={colCounts}
                    step={null}
                    tooltip={{
                        formatter: (value) => colCounts[value],
                    }}
                    />
                </div>
                <span>Filas:</span>
                <div
                    style={{
                    width: '50%',
                    marginBottom: 48,
                    }}
                >
                    <Slider
                    min={0}
                    max={Object.keys(rowCounts).length - 1}
                    value={rowCountKey}
                    onChange={setRowCountKey}
                    marks={rowCounts}
                    step={null}
                    tooltip={{
                        formatter: (value) => rowCounts[value],
                    }}
                    />
                </div>
          </Col>
          <Col span={6}>
            <h1 >Monitor en vivo</h1>
          </Col>
        </Row>
        
        {rows}


     
    </>
  )
}

export default LiveContent