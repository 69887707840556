import "./Note.css";
import Tone from "./Tone.js";
import Price from "./Price";
import React, { useState } from 'react';
import { WhatsAppOutlined, SearchOutlined, FilePdfOutlined, EyeOutlined} from '@ant-design/icons';
import {  Avatar, Button, } from 'antd-mobile';
import {  Card, Collapse,  Drawer, ConfigProvider } from "antd";
import ReactPlayer from 'react-player';

const { Meta } = Card;



const fullNote = ({title = "Sin titulo", description = "", link ="", linkAlt="", linkAlt2="", linkAlt3=""}) =>{

  const getTypeLink = (link) =>{
    if(link===null){
      return "No hay recursos."
    }
    if(link.includes("mp4")){
      return "Ver video: "
    } else if (link.includes("mp3")){
      return "Escuchar audio: "
    } else if (link.includes("pdf")){
      return "Ver pdf: "
    } else if (link.includes(".")){
      return "Visitar recurso: "
    } else {
      return "No hay mas recursos para esta nota."
    }
  }

  const getContentLink=(link) =>{
    if(link.includes(".mp4")){
      return (<ReactPlayer url={link} width="100%" controls />)
    } else if (link.includes(".mp3")){
      return (<ReactPlayer url={link} width="100%" controls height={50} />)
    } else if (link.includes(".pdf")){
      return (<object width="100%" height="75%" data={link} type="application/pdf">   </object>)
    } else if (link.includes(".jpg")){
      return (<img src={link} alt="Imagen de la nota" ></img>)
    } 
    
    else if (link.includes(".")){
      return (<a 
                title="Nota original"
                href={link}
                target="_blank"
                rel="noreferrer"
                > Visitar el sitio web original </a>)
    } else {
      return "No hay mas recursos para esta nota."
    }



  }

  const items: CollapseProps['items'] = linkAlt3 ? 

    [
    {
      key: '1',
      label: getTypeLink(link),
      children: getContentLink(link),
    },
    {
      key: '2',
      label: getTypeLink(linkAlt),
      children: getContentLink(linkAlt),
    },
    {
      key: '3',
      label: getTypeLink(linkAlt2),
      children: getContentLink(linkAlt2),
    },
    {
      key: '4',
      label: getTypeLink(linkAlt3),
      children: getContentLink(linkAlt3),
    }]:
    linkAlt2 ? 
      [{
        key: '1',
        label: getTypeLink(link),
        children: getContentLink(link),
      },
      {
        key: '2',
        label: getTypeLink(linkAlt),
        children: getContentLink(linkAlt),
      },
      {
        key: '3',
        label: getTypeLink(linkAlt2),
        children: getContentLink(linkAlt2),
      }]:
      linkAlt ? [
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        },
        {
          key: '2',
          label: getTypeLink(linkAlt),
          children: getContentLink(linkAlt),
        }]:[
        {
          key: '1',
          label: getTypeLink(link),
          children: getContentLink(link),
        }]
  ;

  
  return(

    <Card
    title={title}
    
    > 
      <Collapse items={items} >
      </Collapse>
    <p></p>
    <Meta
      title={<h3>{"Texto completo de la nota: "}</h3>}
      
    />
    <p>{description}</p>
  </Card>


  );}
  


const getType = (type) =>{
  if(type === "web"){
    return "Web"
  } else if(type === "radio"){
    return "Radio"
  } else if(type === "graphics"){
    return "Gráfica"
  } else if(type === "social"){
    return "Redes Sociales"
  } else if(type === "tv"){
    return "Televisión"
  } else {
    return ""
  }
}


const Note = ({ linkAlt ="", linkAlt2="", linkAlt3="", web_username = "", type = "Nota: ", price = "0", cm2 = "0", duration = "0", link = "", keys = [], date = "", program = "", source = "", loading="true", journalist="Periodista", title="Sin titulo", description="No hay una descripción asociada", avatar="https://xsgames.co/randomusers/avatar.php?g=pixel"}) => {


const [open, setOpen] = useState(false);
const showDefaultDrawer = () => {
  
  setOpen(true);
};
const onClose = () => {
  setOpen(false);
};

return (
  <>
  <Drawer placement="bottom" title="Nota completa." open={open} onClose={onClose} height="90%">
     {fullNote({title, description, link, linkAlt, linkAlt2, linkAlt3})}
  </Drawer>
      <ConfigProvider
      theme={{
        components: {
          Card: {
            actionsLiMargin : "20px"
          },
        },
      }}
    >

  <Card
    style={{ margin: "5px",  
      backgroundColor: "#EAECEE",
      borderRadius: "16px", 
      marginRight: "3px", 
      boxShadow: "2px 2px 14px 2px rgba(208, 216, 243, 0.6)", 
      width: "100%", 
      height:"16%",
      }}
    cover={

      <div class="image60a0">
          <img
            alt="fuente"
            src={source}
            class="image600"
          />
      </div>
    }

    title={ type !== "" ? getType(type)+": "+ title : title
                 }
    loading={loading}
    actions={[
        <Button onClick={showDefaultDrawer}>
        <SearchOutlined/>
      </Button>,
        <a href={link} target="_blank" rel="noreferrer"><EyeOutlined /></a>,
        <FilePdfOutlined key="pdf" />,
        <WhatsAppOutlined key="share" />,
        
    ]}
  >
    
    <Meta
      
      title={type !== "social" ?  program : ""}
      description = {type !== "social" ? <Price duration = {duration} cm2 = {cm2} price = {price} date = {date}/> : "" } 

    />
    <Meta
      avatar={<Avatar src={avatar} />}
      title={journalist}
      

    />
    <p className="note-description">{description}</p>
    <p></p>
    { keys.map(key => (
        <Tone name = {key.name} color = {key.symbol ? key.symbol : "="}/>
        )) }
    
  </Card>
  </ConfigProvider>
  
  </>
)}
;

export default Note;