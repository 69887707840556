import React, {useEffect} from 'react';
import './App.css';
import MainMenu from './components/Main/mainMenu';
import Index from './components/Index';
import MobileIndex from './components/Mobile/Index';
import { HashRouter, BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NotFound from './components/NotFound'

export default function App() {
  useEffect(() => {
    document.title = "CVAMedios - Clientes";
  });
  return (
    <>
    
    <HashRouter>
      <Routes>
        <Route path="/" Component={Index} title="Index Page"></Route>
        <Route path="/home" Component={MainMenu} title="Index Page"></Route>
        <Route path="/index" Component={MobileIndex} title="Index Page"></Route>
      </Routes>
    </HashRouter>
    
    </>
    
  );
}
