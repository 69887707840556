import React from 'react'
import {SmileTwoTone, FrownTwoTone, MehTwoTone } from '@ant-design/icons';
function Tone(props) {
  return (
    <React.Fragment>
        {"Clave: " + props.name + " Tono: " } { props.color.includes("-") ? <FrownTwoTone twoToneColor={"#a83232"} style={{ fontSize: '180%'}}/> : props.color.includes("+") ? <SmileTwoTone twoToneColor={"#25cf13"} style={{ fontSize: '180%'}}/> : <MehTwoTone style={{ fontSize: '180%'}}/>}<br></br>
    </React.Fragment>
  )
}

export default Tone