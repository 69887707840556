import React, {useEffect, useState, useRef, memo, useCallback} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table, DatePicker, Select, Space, Input, AutoComplete } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined, SearchOutlined} from '@ant-design/icons'
import { WordCloud} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import { isEqual } from "lodash-es";
import moment from 'moment';
import Highlighter from 'react-highlight-words';
const { Search } = Input;
const { Option } = Select;


const Graphic: React.FC<any> = memo(
  ({ data, cols, onReady, fullScreen, options }) => {
    const [col, setCol] = React.useState(2);
    
    const [wordSearch, setWordSearch] = React.useState("");
    const [highlightWords, setHighlightWords] = React.useState(JSON.parse(localStorage.getItem("keywords")));
    const onSearch = (value, _e, info) => setWordSearch(value);
    useEffect(() => {
      setCol(cols);
    }, [cols]);
    const config = {
      data,
      wordField: 'name',
      weightField: 'value',
      colorField: 'value',
      color: ( item, datum ) => {
        return (highlightWords.includes(item["datum"]?.name) ? 'red' : "#122c6a");
      },
      legend:{
        rail: {
          type: 'size',
          defaultLength:700,
        }
      },
      wordStyle: {
        fontFamily: 'Verdana',
        fontSize: [14,40],
        rotation:0,
      },
      random: () => 0.5,
    };

    return <> Buscar palabra: 
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '360px' }}
                        placeholder="Selecciona palabras a buscar"
                        defaultValue={[]}
                        onChange={(value)=> setHighlightWords(value)}
                        optionLabelProp="name"
                        optionFilterProp="value"
                      >
                        {
                          data.map(data => (
                            <Option value={data.name} label={data.name}>
                              <Space>
                                {data.name + " - "+data.value+ " apariciones"}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select> <Button onClick={()=>{setHighlightWords(JSON.parse(localStorage.getItem("keywords")))}}>Ver mis palabras clave</Button> <Button onClick={()=>{setHighlightWords([])}}>Limpiar toda la busqueda</Button> <WordCloud  {...config} /> </>;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);




const dateFormat = 'DD/MM/YYYY';
function IaWordCloud(props) {

 

    const dateFormat = 'YYYY-MM-DD';

  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
      const [data, setData] = React.useState([]);
      const [col, setCol] = React.useState(2);
      const [filterDate, setFilterDate] = React.useState(moment().format("YYYY/MM/DD"));
      const [filterMedia, setFilterMedia] = React.useState();
      const [mediaData, setMediaData] = React.useState([]);
      const [datacp, setDatacp] = React.useState([]);   
      const [loading, setLoading] = React.useState(true);
      const [width, setWidth] = React.useState(window.innerWidth);
      
  const [height, setHeight] = React.useState(window.innerHeight);


const handleChangeMedia = (values) => {
    setFilterMedia(values);
};
const handleChangeDate = (date, dateString) => {
    setFilterDate(dateString);
};
const getMediaData = () => {        
    client.get("api/media?limit=1000&type_notes=radio").then(res=>{
      setMediaData(res["data"]["data"]["rows"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
      
      useEffect(() => {
      }, [data]);
      useEffect(() => {
        getNotes();
      }, [filterDate, filterMedia]);
      useEffect(() => {
        getMediaData();
      }, [])
      useEffect(() => {
        setTimeout(function(){
          setData([])
          getNotes();
        }, 500);
      }, [props.col, width, height])

  const getNotes = () => {
    if(datacp[0]){

        setTimeout(function(){setData(datacp);},500);
        return;
    }    
    client.get("api/wordcloudia?date="+filterDate+"&mediaId="+(filterMedia?filterMedia:"")).then(res=>{
      setData(res["data"]["data"]);
      setDatacp(res["data"]["data"]);
      setLoading(false);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
        setLoading(false);
      });
    }
    const getWordCloud = () => {
        setLoading(true);
        client.get("api/wordcloudia?date="+filterDate+"&mediaId="+(filterMedia?filterMedia:"")).then(res=>{
          setData(res["data"]["data"]);
          setDatacp(res["data"]["data"]);
          setLoading(false);
          }).catch(err => {
            console.log("ERROR OBTENIENDO DATOS<: " + err)
            setLoading(false);
          });
        }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVAWordCloud-"+props.filterDates[0]+" a " + props.filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVAWordCloud-"+props.filterDates[0]+" a " + props.filterDates[1], pdfOptions:{ w:'300', h:'200' ,  orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }

  const handle = useFullScreenHandle();
  const [fullScreen, setFullScreen] = React.useState(false);
  const toggleFullScreen = () => {
    setFullScreen (!fullScreen);
    
  }
  useEffect(()=>{
    if(fullScreen){
      handle.enter();
      
    } else {
      handle.exit();
    }
  }, [fullScreen])
  const reportChange = useCallback((state, hand) => {
    setFullScreen(state);
  }, [handle]);

  const [graphic, setGraphic] = React.useState(true);

  const toggleGraphic = () => {
      setGraphic(!graphic);
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    
    {
      title: 'Palabra',
      dataIndex: 'name',
      key: 'media',
      render: (text) =>  {return <>{ text}</>;},
      sorter: (a, b) => (!a.name || !b.name) ? false:a.name.localeCompare(b.name),
      width: '5%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Cantidad de apariciones',
      dataIndex: 'value',
      key: 'value',
      render: (text) =>  {return text},
      
      width: '5%',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.value - b.value,
    },
    
  ];
    
  return (
    <>

<FullScreen handle={handle} onChange={reportChange}>


      <h3>{graphic ?"Nube de palabras":"Tabla de palabras"} 
                 
                {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>}
                  
                  
                  
                   </h3> 
                Fecha a consultar: <DatePicker defaultValue={""} onChange={handleChangeDate} format={"YYYY/MM/DD"} />
                 - Medio a consultar: 
                      <Select
                        mode="single"
                        allowClear
                        style={{ width: '360px' }}
                        placeholder="Selecciona el medio que quieres consultar"
                        defaultValue={[]}
                        onChange={handleChangeMedia}
                        optionLabelProp="label"
                        optionFilterProp="label"
                      >
                        {
                          mediaData.map(data => (
                            <Option value={data.id} label={data.name}>
                              <Space>
                                {data.name}
                              </Space>

                            </Option>
                          ))
                        }
                      </Select>
                      -<Button
                            loading={loading}
                            type="primary"
                            onClick={() => getWordCloud()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                            width: 90,
                            }}
                            
                        >
                            Buscar
                        </Button>
                <div ref={referencecomp} style={{height: fullScreen? height-150 : height-300}}>
                {
                    graphic &&  <Graphic options={data.map(()=> data.name? data.name: "más")} fullScreen = {fullScreen} cols = {col} data={data}></Graphic>

                  }
                  { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ position: ["bottomLeft"] }} 
                        size={"medium"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record, index) => record.media + record.name + record.value}
                        scroll={{ y: height-300 }}
                        />
                    
                    </>
                  }
                   
                   {(downloadJpg>0) && 
                    <>
                      <Row>
                        <Col span={18}>
                            Nube de las palabras mas usadas en mis notas.<br/>
                          Rango de fechas: {"Desde: " + props.filterDates[0]+ " hasta "+props.filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                      
                      
                    </>
                  }
                   </div>

                  
                  
                   </FullScreen>   
    </>
  )
}

export default IaWordCloud