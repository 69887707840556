import React, {useEffect, useRef, memo, useCallback} from 'react'
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import {Button, Image, Row, Col, Table } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined, UserOutlined} from '@ant-design/icons'
import {  DatePicker, } from 'antd';
import { Bar} from '@ant-design/plots';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEqual } from "lodash-es";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
const Graphic: React.FC<any> = memo(
  ({ data, onReady }) => {
    const config = {
      data,
      xField: 'value',
      xAxis:{
        title: {
          text: 'Porcentaje de aparición de cada periodista en mis notas.',
          style: {
            fontSize: 16,
          },
        },
      },
      yAxis:{
        title: {
          text: 'Medio de comunicación',
          style: {
            fontSize: 16,
          },
        },
      },
      yField: 'type',
      seriesField: 'name',
      isPercent: true,
      isStack: true,
  
      /** 自定义颜色 */
      // color: ['#2582a1', '#f88c24', '#c52125', '#87f4d0'],
      label: {
        position: 'middle',
        content: (item) => {
          return (item.value*100).toFixed(1)+"%";
        },
        offset: 10,
        layout: [
          // 柱形图数据标签位置自动调整
          {
            type: 'interval-adjust-position',
          }, // 数据标签防遮挡
          {
            type: 'interval-hide-overlap',
          }, // 数据标签文颜色自动调整
          {
            type: 'adjust-color',
          },
        ],
      },
    };
    return <Bar {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);


const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
function NotesByJournalist() {
  const jar = new CookieJar();
      const client = wrapper(axios.create({ jar }));
      client.defaults.baseURL = 'https://san.cvamedios.com/';
      client.defaults.headers.common['Content-Type'] = 'application/json';
      client.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization';
      client.defaults.headers.common['Accept'] = 'application/json';
      client.defaults.headers.common.Authorization=  'Bearer ' + localStorage.getItem("token")
  const [data, setData] = React.useState([]);
  useEffect(() => {
  }, [data]);
  const [filterDates, setFilterDates] = React.useState(["2020/01/01","2025/12/31"]);
  useEffect(() => {
    getNotes();
  }, [filterDates]);


  const getNotes = () => {        
    client.get("api/notesperjournalist?range={\"start\":\""+filterDates[0]+"T00:00:00\",\"end\":\""+filterDates[1]+"T23:59:59\"}&filters={\"client_id\":\""+localStorage.getItem("clientId")+"\"}").then(res=>{
      setData(res["data"]["data"]);
      }).catch(err => {
        console.log("ERROR OBTENIENDO DATOS<: " + err)
      });
    }
    const [downloadJpg, setDownloadJpg] = React.useState(0);
    const [typeDownload, setTypeDownload] = React.useState(null);
    useEffect(()=>{
      if(downloadJpg){
        setTypeDownload(downloadJpg)
        
      }
    }, [downloadJpg])
    useEffect(() => {
      if(typeDownload){
        if(typeDownload===1){
          setTimeout(()=>{exportComponentAsJPEG(referencecomp, {fileName:"CVAPeriodistas-"+filterDates[0]+" a " + filterDates[1]});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
          
        } else {
          
          setTimeout(()=>{exportComponentAsPDF(referencecomp, {fileName:"CVAPeriodistas-"+filterDates[0]+" a " + filterDates[1], pdfOptions:{ w:'280', h:'200' ,  orientation:'l'}});
        ;
        setTypeDownload(0);
        setDownloadJpg(0);},1);
        }
      }
    }, [typeDownload]);
    const referencecomp = useRef();
  const download= (type) => {
    setDownloadJpg(type)
  }


    function onSelectDate(date, dateString) {
      setFilterDates([dateString[0],dateString[1]]);
    }

    const handle = useFullScreenHandle();
    const [fullScreen, setFullScreen] = React.useState(false);
    const toggleFullScreen = () => {
      setFullScreen (!fullScreen);
      
    }
    useEffect(()=>{
      if(fullScreen){
        handle.enter();
        
      } else {
        handle.exit();
      }
    }, [fullScreen])
    const reportChange = useCallback((state, hand) => {
      setFullScreen(state);
    }, [handle]);
  
    const [graphic, setGraphic] = React.useState(true);

      const toggleGraphic = () => {
          setGraphic(!graphic);
      }
    
      const columns = [
        {
          title: 'Medio',
          dataIndex: 'type',
          key: 'media',
          render: (text) =>  {return text},
          
          width: '5%',
              sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
          title: 'Valoración',
          dataIndex: 'name',
          key: 'media',
          render: (text) =>  {return <><UserOutlined />{ text}</>;},
          sorter: (a, b) => (!a.name || !b.name) ? false:a.name.localeCompare(b.name),
          width: '5%',
        },
        {
          title: 'Cantidad de notas',
          dataIndex: 'value',
          key: 'value',
          render: (text) =>  {return text},
          
          width: '5%',
          sorter: (a, b) => a.value - b.value,
        },
        
      ];
  return (
    <>
              <FullScreen handle={handle} onChange={reportChange}>
                  <h2>Participación de periodistas en cada medio: {
                  !fullScreen && 
                  <Button type="dashed" shape="round" icon={<DownloadOutlined />} onClick={()=>download(1)} >Imagen JPG </Button> }
                  {fullScreen && 
                  <Button type="dashed" shape="round" icon={<FullscreenExitOutlined />} onClick={()=>toggleFullScreen()} > Salir de Pantalla Completa</Button>}
                  {!fullScreen && graphic && 
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleFullScreen()} > Pantalla Completa</Button>}
                  {!fullScreen &&
                  <Button type="dashed" shape="round" icon={<FullscreenOutlined />} onClick={()=>toggleGraphic()} > Ver tabla</Button>} 
                  <RangePicker
                      onChange={onSelectDate}
                      defaultValue={[dayjs('2020/01/01 ', dateFormat), dayjs('2025/12/31', dateFormat)]}
                      format={dateFormat} /> </h2>
                  
                  <div ref={referencecomp} style={{height: fullScreen? "90%" : "500px"}}>
                  {
                    graphic &&  <Graphic data={data} />

                  }
                  { !graphic &&
                    <>
                      <Table  
                        virtual bordered={true} 
                        pagination={{ position: ["bottomLeft"] }} 
                        size={"middle"} columns={columns} 
                        dataSource={data} 
                        rowKey={(record, index) => record.media + record.name + record.value}
                        scroll={{ y: 420 }}
                        />
                    
                    </>
                  }
                  {((downloadJpg>0) || fullScreen) && 
                   <>

<Row>
                        <Col span={16}>
                          Participación de periodistas generada automáticamente. <br></br>
                     Rango de fechas: {"Desde: " + filterDates[0]+ " hasta "+filterDates[1]}
                        </Col>
                        <Col span={3}><Image width={200} src="logocva.png"></Image></Col>
                      </Row>
                     
                   </>
                 }
                  </div>
                  
                  </FullScreen> 
    </>
  )
}

export default NotesByJournalist